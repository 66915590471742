@font-face {
    font-family: 'Simple-Line-Icons-Pro';
    src:url('../fonts/Simple-Line-Icons-Pro.eot?jq15et');
    src:url('../fonts/Simple-Line-Icons-Pro.eot?jq15et#iefix') format('embedded-opentype'),
        url('../fonts/Simple-Line-Icons-Pro.ttf?jq15et') format('truetype'),
        url('../fonts/Simple-Line-Icons-Pro.woff?jq15et') format('woff'),
        url('../fonts/Simple-Line-Icons-Pro.svg?jq15et#Simple-Line-Icons-Pro') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'Simple-Line-Icons-Pro';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-1-circle-left:before {
  content: "\e801";
}
.icon-arrow-1-left:before {
  content: "\e805";
}
.icon-arrow-2-circle-right:before {
  content: "\e80e";
}
.icon-arrow-2-left:before {
  content: "\e811";
}
.icon-arrow-2-right:before {
  content: "\e812";
}
.icon-email-send:before {
  content: "\e86b";
}
.icon-email:before {
  content: "\e86d";
}
.icon-message-1:before {
  content: "\e87e";
}
.icon-old-telephone:before {
  content: "\e8c7";
}
.icon-printer:before {
  content: "\e8cc";
}
.icon-cart-1:before {
  content: "\e903";
}
.icon-bold:before {
  content: "\e9a0";
}
.icon-copy-plain-text:before {
  content: "\e9b8";
}
.icon-list-bullets:before {
  content: "\e9c2";
}
.icon-list-numbers:before {
  content: "\e9c3";
}
.icon-text-italic:before {
  content: "\e9dc";
}
.icon-cancel-circle:before {
  content: "\e9f2";
}
.icon-check-circle:before {
  content: "\e9f6";
}
.icon-check:before {
  content: "\e9f9";
}
.icon-close:before {
  content: "\e9fa";
}
.icon-config-1:before {
  content: "\e9fb";
}
.icon-edit-1:before {
  content: "\ea0b";
}
.icon-list-4:before {
  content: "\ea32";
}
.icon-magnifier-1:before {
  content: "\ea38";
}
.icon-outside:before {
  content: "\ea48";
}
.icon-paper-clip:before {
  content: "\ea49";
}
.icon-plus-circle:before {
  content: "\ea51";
}
.icon-question:before {
  content: "\ea57";
}
.icon-reload:before {
  content: "\ea5c";
}
.icon-share-3:before {
  content: "\ea66";
}
.icon-trash:before {
  content: "\ea78";
}
.icon-file-export:before {
  content: "\ea9a";
}
.icon-chef-hat-1:before {
  content: "\eaf9";
}
.icon-fire-1:before {
  content: "\eda6";
}
.icon-facebook:before {
  content: "\ecbd";
}
.icon-google-plus:before {
  content: "\ecc7";
}
.icon-instagram:before {
  content: "\eccd";
}
.icon-twitter:before {
  content: "\ece3";
}
.icon-youtube:before {
  content: "\eced";
}
.icon-rss:before {
  content: "\ecdb";
}
.icon-calendar:before {
  content: "\ecf8";
}
.icon-clock:before {
  content: "\ecfa";
}
.icon-user-circle:before {
  content: "\edfd";
}
.icon-link-3:before {
  content: "\ee69";
}
.icon-calendar-time:before {
  content: "\ecf7";
}
.icon-badge-2:before {
  content: "\ee28";
}
.icon-medal-5:before {
  content: "\ee3a";
}
.icon-star:before {
  content: "\ee40";
}
.icon-pause-circle:before {
  content: "\eb87";
}
.icon-account-book-1:before {
  content: "\edd8";
}
.icon-align-bottom:before {
  content: "\e9ea";
}
.icon-spell-check:before {
  content: "\e9d7";
}
.icon-arrow-1-circle-right:before {
  content: "\e802";
}
.icon-arrow-1-right:before {
  content: "\e806";
}
.icon-arrow-1-up:before {
  content: "\e80b";
}
.icon-back-7:before {
  content: "\e82c";
}
.icon-check-circle-2:before {
  content: "\e9f5";
}
.icon-graduation-cap:before {
  content: "\e94c";
}
.icon-backward:before {
  content: "\e9ee";
}
.icon-info:before {
  content: "\ea26";
}
.icon-check-square:before {
  content: "\e9f8";
}
.icon-hamburger-menu-1:before {
  content: "\ea20";
}
.icon-minus-circle:before {
  content: "\ea3d";
}
.icon-emoticon-grin:before {
  content: "\ed9f";
}
.icon-ID-card:before {
  content: "\ede6";
}
.icon-file-copy:before {
  content: "\ea8e";
}
.icon-more-squares-vertical-filled:before {
  content: "\ea42";
}
.icon-more-circles-vertical:before {
  content: "\ea41";
}
.icon-settings:before {
  content: "\ea62";
}
.icon-warning-circle:before {
  content: "\ea7c";
}
.icon-pencil2:before {
  content: "\e982";
}
.icon-more-circles-horizontal:before {
  content: "\ea40";
}
.icon-emoticon-smile:before {
  content: "\eda0";
}
.icon-carrot:before {
  content: "\eaf5";
}
.icon-bottle-milk:before {
  content: "\eaec";
}
.icon-glass-water:before {
  content: "\eb21";
}
.icon-meal-time:before {
  content: "\eb33";
}
.icon-fork-knife-2:before {
  content: "\eb14";
}
.icon-stop:before {
  content: "\eba6";
}
.icon-pear-apple:before {
  content: "\eb39";
}
.icon-heart:before {
  content: "\ee32";
}
