.user-memberships-details-container {
  position: relative;
  width: 100%;
  padding: 2em 1em;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  margin: 1em 0;

  a {
    text-decoration: underline;
    color: #002538;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.6;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: minmax(20px, 70px) repeat(4, minmax(0, 1fr)) 172px;
    border-bottom: 1px solid rgba(0,0,0, 0.5);
    align-items: center;



    .buttonItem {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      .action-icons {
        font-size: 16px;
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: minmax(20px, 70px) repeat(2, minmax(0, 1fr)) 58px;



      .button-name {
        display: none;
      }
    }

    &.grid-head {
      font-weight: 600;
      background: rgba(229, 233, 235, 0.65);
      border-top: 1px solid rgba(0,0,0, 0.5);
    }

    .grid-column {
      padding: 10px 6px;
    }
  }

  .toggleInfo {
    position: absolute;
      top: -10px;
      right: -8px;
    width: 40px;
    height: 40px;
    transform: rotate(90deg);
  }

  .membership-header-container {
    width: 100%;
    position: relative;
    padding-bottom: 2em;

    .membership-header {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .membership-list-container {
    width: 100%;
  }

  &[data-open="true"] {
    .membership-list-container {
      display: inline-block;
    }
    .toggleInfo {
      transform: rotate(90deg);
    }
  }

  &[data-open="false"] {
    .membership-list-container {
      display: none;
    }

    .toggleInfo {
      transform: rotate(-90deg);
    }

    .membership-header-container {
      padding-bottom: 0;
    }
  }


}
