@import './src/assets/styles/_colors.scss';

@mixin submenu-icon($font-size: 24px) {
    position: absolute;
    left: 0;
    top: 0;
    font-size: $font-size;
    line-height: 35px;
    color: $color-gr-red;
}

.Menu {
    .icon-user-circle{
        color: white;
    }
    section {
        padding-top: 12px;
        overflow: hidden;

        h3 {
            clear: both;
            margin-bottom: 12px;
            border-bottom: 1px $color-outlines solid;
            font-size: 18px;
            line-height: 46px;
            font-weight: 600;
            color: $color-title-blue;
        }

        ul {
            padding: 2px 0 14px;
            line-height: 1.75;
            color: $color-gr-red;

            &:nth-child(3) {
                margin-top: -16px;
            }
        }

        li {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 959px) {

    .Menu .Menu-auth{
        right: 20px;
    }
}



    .Menu {
        position: fixed;
        z-index: 10;
        left: 0;
        top: 49px;
        width: 100%;
        background: #008766;

        &-auth {
            position: fixed;
            right: 80px;
            top: 0;
            width: 300px;
            height: 60px;
            padding-top: 8px;
            text-align: right;

            a {
                display: inline-block;
                line-height: 36px;
            }
        }

        &-offline,
        &-online {
            display: inline-block;
        }

        &-online {
            position: relative;
            min-width: 120px;

            & > a {
                font-size: 1.5em;
            }

            ul {
                position: absolute;
                z-index: 10;
                display: none;
                color: black;
                width: 250px;
                left: -120px;
                padding: 3px 16px 8px 16px;
                margin-top: 12px;
                margin-left: -16px;
                border-radius: 0 0 6px 6px;
                box-shadow: 0 4px 4px 0 rgba($color-black, 0.3);
                background: $color-white;
                text-align: left;
            }

            li {
                padding: 3px 0 3px 0;

                a {
                    position: relative;
                    display: inline-block;
                    padding-left: 31px;
                }

                i {
                    @include submenu-icon(21px);
                }
            }


            &.is-open ul {
                display: block;
            }
        }

        &-cart {
            position: relative;
            padding-left: 28px;
            margin-left: 32px;
            line-height: 35px;

            i {
                @include submenu-icon(21px);
            }
        }

        &-nav {
            display: none;
            width: calc(100% + 30px);
            padding-top: 6px;
            margin-left: -15px;

            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }

        &-cols {
            float: left;
            width: 25%;
            padding: 0 15px;

            &:last-child {
                width: 50%;

                section:first-child ul {
                    float: left;
                    width: 50%;

                    &:nth-child(2) {
                        padding-right: 15px;
                    }

                    &:last-child {
                        padding-left: 12px;
                        margin-top: 0;
                    }
                }

                section:last-child {
                    width: 50%;
                    padding-right: 15px;
                }
            }
        }
    }

    .Menu.is-open {
        min-height: calc(100% - 61px);
        max-height: calc(100% - 61px);
        padding-bottom: 24px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .Menu-nav {
            display: block;
        }

        .Menu-auth {
            margin-left: 232px;
        }
    }


@media screen and (min-width: 1210px) {
    .Menu-auth {
        left: 50%;
        right: auto;
        margin-left: 240px;
    }
}
