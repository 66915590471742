@import "../_colors.scss";

body {
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #FF0000;
  }


  .homePageContainer {
    width: 100%;
    height: 100%;
    padding: 25px 12px;
    display: grid;
  grid-gap: 20px;
  grid-template-columns: 100%;
    .homeMenuMemberOverview {
      grid-row: 2;
    }
    .homeMissingPayments {
      grid-row: 4;
    }

    .homeNotificationContainer {
      grid-row: 3;
      .homeNotificationWrapper {

        .home_notification_href {
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }

        .homeNotificationItem {
          width: 100%;
          background: white;
          border-radius: 6px;
          color: $notification-text;
          margin-bottom: 16px;
          padding: 2em;
          transition: box-shadow 0.3s ease;
          box-shadow: 1px 2px 4px rgba(0, 0, 0, .6);

          &:hover {
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 1);
          }

          &:first-child {
            margin-top: 0;
          }

          .homeNotificationHeadContainer {
            position: relative;
            width: 100%;
            height: 20px;

            .homeNotificationHeadItem {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);

              &.niHeadName {
                left: 0px;
                font-size: 20px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.4;
                letter-spacing: 0.2px;
                color: black;
              }

              &.niHeadTime {
                right: 0px;
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.4;
                letter-spacing: 0.2px;
                color: black;

              }
            }
          }

          .homeNotificationContent {
            position: relative;
            width: 100%;
            font-size: 14px;
            // padding-top: 1.6em;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.2px;
            color: black;

            a {
              text-decoration: underline!important;
              color: #0969da!important;
            }

            p {
              margin-bottom: 1.2em!important;
            }

            h1, h2, h3 {
              margin-top: 1.2em!important;
            }

            b {
              font-weight: 600!important;
            }
          }
        }
      }
    }

    .homeMenuContainer {
      grid-row: 1;
      .homeMenuWrapper {
        // display: grid;
        // grid-template-columns: 50% 50%;
        // grid-template-rows: 88px;
        // grid-gap: 10px;
        width: calc(100% - 10px);
        .homeMenuButton {
            display: block;
            font-size: 20px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 0.2px;
            line-height: 40px;
            padding: 24px 11px;
            border-radius: 6px;
            background: $home-buttons-background;
            color: $home-buttons-text;
            position: relative;
            transition: all 0.3s ease;
            @media screen and (max-width: 600px) {
              font-size: 14px;
            }
            &:after {
                @include menu-arrow();
                right: 11px;
                transform: rotate(180deg);
            }

            &:hover {
              background: rgba($home-buttons-background, 0.5);
            }

        }
      }
    }
  }
}
