.markdown{

    p{
        margin: 2rem 0;
    }
    b,
    strong{
        font-weight: 800;
    }
    i,
    em{
        font-style: italic;
    }
    a{
        text-decoration: underline;
    }
    ol{
        list-style: decimal;
        margin-left: 2rem;
        li{
            
            margin-bottom: .5em;
        }
    }
    ul{
        margin-left: 2rem;
        list-style: disc;
        
    }

}