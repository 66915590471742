@import "../_colors.scss";

.course-container {
  padding: 25px 12px 60px 12px;

  .breadcrumbs {
    margin-bottom: 20px;
  }

  .course-header {
    width: 100%;
    background: #e5e9eb;
    border-radius: 6px;

    .ch-wrapper {
      padding: 12px;
      border-bottom: 1px solid #d7d7d7;
      width: 100%;
      font-size: 13px;

      .ch-item {
        &.ch-grid {
          display: inline-grid;
          grid-template-columns: 1fr auto;
          width: 100%;

          .ch-grid-item {
            align-self: center;
            justify-self: center;

            &.position-left {
              justify-self: left;
            }

            &.position-right {
              justify-self: right;
            }

            .buttonWrapper {
              i {
                font-size: 16px;
              }
            }
          }
        }

        padding: 4px 0;

        &.course-copy {
          display: grid;
          grid-template-columns: calc(100% - 100px) 120px;
          position: relative;

          .linkText {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 22px;
          }

          .copyLink {
            .buttonWrapper {
              .buttonText {
                font-size: 13px;
                line-height: 22px;
                margin-right: 4px;
                font-weight: inherit;
                margin-left: inherit;
              }

              .buttonIcon {
                font-size: 20px;
                font-weight: inherit;
              }
            }
          }

          .copied {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: $color-white;
              font-size: 13px;
              padding: 6px 8px;
              background: rgba($color-dark-blue, 1);
              z-index: 10;
              border-radius: 4px;
          }
        }
      }

      .strong {
        font-weight: 600;
      }

      .day-name {
        text-transform: capitalize;
      }

      .buttonWrapper {
        .buttonIcon {
          font-size: 20px;
        }

        .buttonText {
          font-size: 13px;
          font-weight: 600;
          margin-left: 10px;
          line-height: 23px;
        }
      }
    }
  }

  .course-action-container {
    display: grid;
    grid-template-columns: 130px 200px;
    grid-gap: 10px;

    @media (max-width: 480px) {
      grid-template-columns: auto auto;
    }

    .course-action-wrapper {

      // display: inline-block;

      border: 1px solid #d7d7d7;
      margin-top: 12px;
      border-radius: 6px;

      .courseActionItem {
        padding: 6px 30px;
        text-align: center;

        .buttonIcon {
          font-size: 20px;
        }

        .buttonText {
          font-size: 13px;
          line-height: 22px;
          margin-left: 4px;
        }
      }
    }
  }

  .course-participant-list {

  }

  .course-participant-list {
    margin-top: 40px;
    margin-bottom: 30px;

    .cp-headers {
      .cp-header-item {
        display: grid;
        grid-template-columns: 60% 40%;
        margin-bottom: 30px;

        .cp-text {
          text-align: left;
          font-size: 14px;
          font-weight: 600;

        }

        .cp-action-item {
          text-align: right;
          font-size: 13px;
          font-weight: 500;
          cursor: pointer;
          transition: opacity 0.3s ease;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .participantsGrid {
      display: grid;
      grid-template-columns: 44px auto 20% 20% 20%;
      grid-template-rows: 44px;
      border-bottom: 1px solid #e5e9eb;

      .regularCheckbox {
        width: 20px;
        height: 20px;
        display: inline-block;
        position: relative;
        border: 1px solid $border-gray;
        border-radius: 6px;

        -moz-appearance:none;
        -webkit-appearance:none;
        -o-appearance:none;

        &.userCheckbox {
          border-radius: 50%;
        }

        &:checked {
          background-color: #a4f48e;
          border: 1px solid #00b75d;
          box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
          color: #00b75d;

          &::after {
            content: '\2715';
            font-size: 14px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #008766;
          }
        }
      }

      .headItem {
        text-align: left;
        line-height: 44px;
        border-bottom: 1px solid $border-gray;
        position: relative;
        padding-left: 20px;

        &.firstCol {
          line-height: 55px;
          padding-left: 0px!important;
          text-align: center;
        }

        // &.textItem {
        //   font-size: 20px;
        //
        //   &::before {
        //     @include menu-arrow();
        //     left: 2px;
        //     width: 12px;
        //     height: 12px;
        //     margin-top: 0!important;
        //     transform: translateY(-50%) rotate(270deg);
        //   }
        //
        //   &.active:before {
        //     transform: translateY(-50%) rotate(90deg);
        //   }
        // }

        &.headArrow {
          font-size: 20px;

          @media (max-width: 480px) {
            font-size: 12px;
          }

          &.asc {
            &::before {
              @include menu-arrow();
              left: 2px;
              width: 12px;
              height: 12px;
              margin-top: 0!important;
              transform: translateY(-50%) rotate(270deg);
            }
          }

          &.desc {
            &::before {
              @include menu-arrow();
              left: 2px;
              width: 12px;
              height: 12px;
              margin-top: 0!important;
              transform: translateY(-50%) rotate(90deg);
            }
          }
        }
      }

      .bodyItem {
        padding: 10px 0;
        text-align: left;



        &.firstCol {
          text-align: center;
        }

        &.courseCol.textItem,
        &.dateCol.textItem,
        &.discountCol,
        &.subscriptionCol.textItem {
          font-size: 16px;

          @media (max-width: 480px) {
            font-size: 11px!important;
          }
        }



        &.textItem {
          text-align: left;
          font-size: 20px;
          line-height: 24px;
          padding-left: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;




          &.discountCol {
            color: #ffffff;
            font-size: 16px;

            &.new {
              background: #007a7d;
            }

            &.active {
              background: #42afb6;
            }

            &.pending_paused,
            &.paused {
              background: #fcb414;
            }

            &.pending_canceled,
            &.canceled {
              background: #cb1b4a;
            }

            &.finished {
              background: #cb1b4a;
            }
          }
        }
      }
    }
  }



  .cm-headers {
    display: grid;
    grid-template-columns: 60% 40%;
    margin-bottom: 30px;

    .cm-header-item {
      &.cmg-text {
        text-align: left;
        font-size: 14px;
        font-weight: 600;

      }

      &.show-course-meetings,
      &.close-course-meetings {
        text-align: right;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        transition: opacity 0.3s ease;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .course-meetings {
    .cm-pagination {
      .cmp-grid {
        display: grid;
        grid-template-columns: auto 100px;

        .cmp-item {
          &.left {
            justify-self: left;
          }

          &.right {
            justify-self: right;
          }
        }

        .cmp-container {
          display: inline-flex;
        }
      }

      .cm-pagination-item {
        padding: 10px 8px;
        font-weight: 500;
        cursor: pointer;
        transition: opacity 0.3s ease;
        font-size: 14px;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

}

.cm-month-container {
  width: 100%;

  .cm-month-name {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 14px;
  }

  .cm-month {
    .cm-grid-result {
      display: grid;
      grid-template-columns: 12.5% auto;

        .cm-days-dimension {
          font-size: 0.7em;
          padding: 1vw;
          width: 100%;
          height: 60px;
          border-bottom: 1px solid rgba(0,0,0, 0.12);
        }

        .cm-item-months {
          overflow-x: auto;
          width: 100%;
        }



        .cm-month-header {
          font-weight: 600;
          background: #eee;
          text-align: left;
          padding: 6px 4px;

          @media (max-width: 768px) {
            font-size: 12px;
          }

          @media (max-width: 480px) {
            font-size: 12px;
          }
        }


        .cmm-grid {
          display: grid;
          grid-template-columns: minmax(80px, 14%) minmax(80px, 14%) minmax(80px, 14%) minmax(80px, 14%) minmax(80px, 14%) minmax(80px, 14%) minmax(80px, 14%);



          @media (max-width: 768px) {
            width: fit-content;
          }

          @media (max-width: 480px) {
            width: fit-content;
          }

          .cm-day {
            .cm-day-container {
              width: 100%;
              height: 100%;
              position: relative;

              .cm-day-wrapper {
                position: absolute;
                top: 1px;
                left: 1px;
              }

              .cm-day-time-wrapper {
                position: absolute;
                bottom: 1px;
                right: 1px;

                .cm-day-time {
                  padding: 2px 4px;
                  border-radius: 5px;
                  text-align: center;
                  margin-top: 10px;

                  &:first-child {
                    margin-top: 0px;
                  }

                  &[data-meeting-one=true] {
                    background-color: #a4f48e;
                    color: black;
                  }

                  &[data-meeting-four=true] {
                    background-color: #008766;
                    color: white;
                  }

                  &[data-meeting-two=true] {
                    background-color: #00b75d;
                    color: white;
                  }

                  &[data-blacklisted=true] {
                      background-color: #f24e4e;
                      color: white;
                  }
                }
              }
            }
          }

        }

        margin-bottom: 20px;
      }
    }


}

.week-map {
  display: grid;
  grid-template-columns: auto auto auto;

  .week-map-item {
    height: 30px;
    line-height: 21px;
    padding: 4px 8px;
    font-size: 0.8em;
    color: #fff;
    margin-bottom: 30px;

    &.first {
      background-color: #a4f48e;
      color: #000;
    }
    &.second {
      background-color: #00b75d;
    }
    &.third {
      background-color: #008766;
    }
  }
}

.course-meeting-container {
  padding: 4vw;

  .breadcrumbs {
    margin-bottom: 20px;
  }


}

.year-container {
  margin-bottom: 20px;

  .cmp-container {
    display: grid;
    grid-template-columns: 70px auto 70px;

    .cm-pagination-item {

      &.year {
        text-align: center;
        font-weight: 600;
        font-size: 1.2em;
      }

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }
    }
  }
}


@media (max-width: 992px) {

}
