$color-gr-red: #00b75d;
$color-gr-red-hover: #fc354b;
$color-kitchen-blue: #506796;
$color-kitchen-blue-hover: #5f7bb3;
$color-kitchen-blue-weak: #d9dee9;
$color-kitchen-blue-weak-hover: #e5ecfd;
$color-dark-blue: #232b3c;
$color-dark-blue-rgba: rgb(35, 43, 60);
$color-title-blue: #2a3142;
$color-footer-text: #777f90;
$color-body-text: #505050;
$color-body-text-weak: #8b8b8b;
$color-outlines: #cecece;
$color-outlines-weak: #dddddd;
$color-banner-light: #9fb3d4;
$color-banner-dark: $color-dark-blue;
$color-white: #ffffff;
$color-border-header: #cecece;
$color-black: #000000;
$color-light-blue: #f4f6f7;
$color-black-rgba: rgb(0, 0, 0);
$notification-background: #f2f2f2;
$notification-text: #7b7b7a;
$home-buttons-background: #002538;
$home-buttons-text: white;
$font-color-gray: #4b4b4b;
$border-gray: #d7d7d7;
$gray-list-head: #e5e5e5;
$gray-list-footer: #cccccc;
$light-background: #e5e9eb;
