@import "../_colors.scss";

.SMSPlaceholder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;

  .SMSContainer {
    position: relative;
    width: 100%;
    height: 100%;

    .SMSWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 300px;
      max-height: 364px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 101;
      background: $color-white;
      border-radius: 6px;
      padding: 15px 8px;

      .SMSPopup {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
        	width: 0px;
        	background: transparent;
        }
        &::-webkit-scrollbar-thumb {
        	background: #FF0000;
        }

        .GridContainer {
          display: grid;
          grid-template-columns: 75px auto 75px;
          grid-template-rows: 30px;

          .GridItem {
            .textWrapper {
              text-align: center;
              font-size: 16px;
              line-height: 30px;
            }

            .buttonWrapper {
              .action-icons {
                font-size: 20px;
              }
              .textWrapper {
                font-size: 14px;
                line-height: 20px;
              }
            }

            &.CloseItem {
              text-align: left;
              padding: 5px 10px 5px 0;

              .textWrapper {
                margin-left: 4px;
              }
            }

            &.SendItem {
              text-align: right;
              padding: 5px 0 5px 10px;

              .textWrapper {
                margin-right: 4px;
              }

              &[data-state="sending"] {
                pointer-events: none;

                .textWrapper, .action-icons {
                  visibility: hidden;
                }

                img {
                  display: block;
                }
              }
            }

            .sendButton {
              position: relative;

              img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 30px;
                margin: -4px 0 0 -15px;
                display: none;
              }
            }
          }
        }

        .SMSHeadBottom {
          margin-top: 14px;

          .SMSNameTagContainer {
            position: relative;
            width: 100%;
            background: $gray-list-head;
            border-radius: 4px;
            padding: 0 6px;
            min-height: 40px;
            display: inline-flex;
            font-size: 14px;

            .react-tags__suggestions {
              left: 0px;
              width: 100%;
            }
          }
        }

        .SMSBody {
          margin-top: 20px;

          .SmsCountContainer {
            text-align: right;
            padding: 0px 10px 5px 10px;

            .SmsCountWrapper {
              display: inline-flex;
              font-size: 12px;

              .SmsCountItem {
                margin-left: 5px;
              }
            }
          }

          #SMSEditor {
            width: 100%;
            height: 200px !important;
            background: #e5e5e5;
            border-radius: 6px;
            color: #000000;
            font-size: 12px;
            padding: 12px 8px;
            outline: none;

            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
            	width: 0px;
            	background: transparent;
            }

            &::-webkit-scrollbar-thumb {
            	background: #FF0000;
            }
          }

          .Medium-placeholder {
            width: 100%;
          }
        }
      }
    }
  }

}
