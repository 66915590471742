#CalendarContainer {
  width: 90%;
  position: absolute;
  left: 50%;
  transform: translatex(-50%);



  .courseCalendarContainer {
    width: 100%;
    padding: 3vw 0;

    .course-action-container {
      text-align: left;

      .course-action-wrapper {
        display: inline-flex;

        .course-action-item {
          margin: 12px 10px 12px 0;
          padding: 10px 20px;
          border: 1px solid #d7d7d7;
          font-size: 12px;
          cursor: pointer;
          border-radius: 6px;

          transition: all 0.3s ease;

          &:hover {
            border: 1px solid rgba(215, 215, 215, 0.6);
          }

          i {
            font-size: 20px;
          }

          .buttonText {
            font-size: 13px;
            line-height: 22px;
            margin-left: 4px;
          }
        }
      }
    }


    .breadcrumbsWrapper {
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: black;
      margin-top: 16px;
      text-align: left;
    }


    .courseCalendarBody {
      width: 100%;


      .cc-grid {
        display: grid;
        grid-template-columns: 80px minmax(120px, 14%) minmax(120px, 14%) minmax(120px, 14%) minmax(120px, 14%) minmax(120px, 14%) minmax(120px, 14%) minmax(120px, 14%);
        border-bottom: 1px solid rgba(0,0,0,0.12);
        align-items: center;

        .cc-grid-item {
          padding: 10px;
        }
        .cc-grid-item[data-blacklisted=true] {

          background-color: red;
          color: white;
        }

        .cc-link {
          color: #039be5;
        }

        &.body {
          grid-template-rows: minmax(100px, auto);
        }

        &.header {
          .cc-grid-item {
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
          }
        }



      }

      .roede-course-item {
        width: 100%;
        background: #F1F1F1;
        display: block;
        margin-top: 10px;
        font-size: 1em;
        text-align: left;
        padding: 5px;
        border-radius: 1px;

        &.course-meeting-item {
          padding: 5px 5px 5px 30px;
          position: relative;

          input[type=checkbox] {
            position: absolute;
            left: 5px;
          }
        }

        &:first-child {
          margin-top: 0px;
        }

        .roede-course-name {
          color: #039be5;
          margin-bottom: 6px;
          font-size: 1em;
        }

        .roede-course-location {


          .roede-course-calendar-item {
            font-size: 0.8em;
            margin-bottom: 3px;

          }

          .roede-course-instructor {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.9em;
            font-weight: bold;
          }

          .roede-course-address {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }


        }

      }
    }
  }
}

.filterWrapper {
  margin-top: 16px;
  margin-bottom: 25px;
  width: 100%;
  background: #002538;
  border-radius: 6px;
  color: white;

  .filterItemContainer {
    position: relative;

    &.filterButton {
      height: 66px;

      .icon-config-1 {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        font-size: 16px;
        font-weight: 400;
      }

      .filterText {
          margin-left: 35px;
          line-height: 66px;
          font-size: 20px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 16px;
        height: 16px;
        margin-top: -8px;
        background: url('./arrow.svg') center center no-repeat;
        background-size: contain;
        right: 11px;
        width: 12px;
        height: 12px;
        margin-top: 0 !important;
        transform: translateY(-50%);
      }

      &.active::after {
        transform: translateY(-50%) rotate(270deg);
      }
    }

    &.filterList {
      .filterDetailsContainer {
        border-top: 1px solid #d7d7d7;
        padding: 16px 12px;

        .filterItem {
          position: relative;
          margin-bottom: 12px;

          .filterItemName {
            font-size: 18px;
            padding-bottom: 12px;
          }

          .filterGridContainer {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-column-gap: 20px;
            grid-row-gap: 10px;

            .filterGridItem {
              span {
                margin-left: 10px;
              }
            }
          }

        }

      }
    }

  }
}

@media (max-width: 992px) {
  #CalendarContainer {
    .courseCalendarBody {
      overflow-x: auto;

      .cc-grid {
        width: fit-content;

        .cc-grid-item {
          &.grid-align-center {
            justify-self: stretch;
            align-self: stretch;
            background-color: #fff;
            position: sticky;
            left: 1px;

            .cc-center {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
          }
        }

      }
    }
  }
}

.tbl thead {
  border-bottom: 2px #ccc solid;
}
.tbl tfoot {
  border-top: 2px #ccc solid;
  font-size: 16px;
}
.tbl th {
  font-weight: 700;
  text-align: center;
}
.tbl tr {
  border-bottom: 1px #ccc solid;
}
.tbl th,
.tbl td {
  padding: 8px 12px;
  white-space: initial;
}
.tbl ul {
  padding-left: 15px;
}
.tbl li {
  list-style: disc;
  margin: 10px 0;
}
.tbl a {
  color: #039be5;
}
.tbl tbody a:hover {
  text-decoration: none;
}
.tbl .nowrap {
  white-space: nowrap;
}
.tbl input {
  width: 60px;
  padding: 6px 0;
  text-align: center;
  border-bottom: 1px #ccc solid;
  color: #002538;

  &:focus {
    border-color: #039be5;
  }
}
.tbl button {
  display: inline-block;
  padding: 0 16px;
  border-radius: 5px;
  line-height: 42px;
  font-weight: 700;
  background: #007bff;
  color: white;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    background: #0069d9;
  }
}
.tbl .inputs {
  background: #f6f6f6;

  &[data-loading="true"] {
    opacity: 0.65;
    pointer-events: none;
  }
}
