@import "../_colors.scss";

.pageContainer {
  padding: 25px 12px 60px 12px;
  font-size: 14px;
  color: $font-color-gray;

  .tcLoadingWrapper {
    padding: 20px 0 14px 0;

    .loading_container {
      text-align: left!important;
    }
  }

  .totalCount {
    font-size: 16px;
    padding: 20px 0 14px 0;

    strong {
      font-weight: 600;
    }
  }


  .paginationWrapper {
    text-align: center;
    padding: 10px 0;

    .paginationContainer {
      display: inline-flex;

      .pageWrapper {
        font-size: 14px;
        padding: 10px 20px;
      }

      .arrowWrapper {
        position: relative;
        padding: 10px;

        &.disable {
          opacity: 0.4;
        }


        &.leftWrapper::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 16px;
          height: 16px;
          margin-top: -8px;
          background: url('./arrow.svg') center center no-repeat;
          background-size: contain;
          right: 11px;
          width: 12px;
          height: 12px;
          margin-top: 0 !important;
          transform: translate(-50%, -50%);
        }

        &.rightWrapper::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 16px;
          height: 16px;
          margin-top: -8px;
          background: url('./arrow.svg') center center no-repeat;
          background-size: contain;
          right: 11px;
          width: 12px;
          height: 12px;
          margin-top: 0 !important;
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
  }

  .pageHeadWrapper {
    .searchWrapper {
      position: relative;

      #search {
        width: 100%;
        height: 66px;
        border: 1px solid $border-gray;
        border-radius: 6px;
        padding: 0 12px;
        font-size: 20px;
      }

      .icon-item {
        position: absolute;
        right: 12px;

        top: 50%;
        transform: translateY(-50%);
      }

      .userSearchList {
        position: absolute;
        width: 100%;
        top: 72px;
        left: 0;
        background: #fff;
        z-index: 10;
        box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.15);
        border-radius: 6px;

        .qsItem {
          line-height: 40px;
          padding: 0 15px;
          border-top: 1px solid rgba(0,0,0,0.15);
          cursor: pointer;

          &:first-child {
            border-top:none;
          }

          .courseName {
            line-height: 25px;
            padding-top: 8px;
          }
          .courseBookCode {
            line-height: 15px;
            font-size: 10px;
            padding-bottom: 10px;
          }
        }
      }


    }

    .filterWrapper {
      margin-top: 16px;
      width: 100%;
      background: $home-buttons-background;
      border-radius: 6px;
      color: $home-buttons-text;

      .filterItemContainer {
        position: relative;

        &.filterList {

        }

        &.filterButton {
          height: 66px;

          &:after {
              @include menu-arrow();
              right: 11px;
              width: 12px;
              height: 12px;
              margin-top: 0!important;
              transform: translateY(-50%);
          }

          &.active:after {
            transform: translateY(-50%) rotate(270deg);
          }

          .icon-config-1 {
            position: absolute;
            left: 11px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            font-size: 16px;
            font-weight: 400;
          }

          .filterText {
            margin-left: 35px;
            line-height: 66px;
            font-size: 20px;
          }
        }

        &.filterList {

          .filterDetailsContainer {
            border-top: 1px solid $border-gray;

            .notificationText {
              padding: 12px 16px;
              font-size: 14px;
            }

            .filterItem {
              position: relative;
              padding: 16px 12px;

              &:first-child {
                padding-bottom: 0px;
              }

              .filterItemName {

                &.filterPeriodWrapper {
                  font-size: 14px;
                  font-weight: 600;

                  .filterDateWrapper {
                    margin: 0 5px;
                  }
                }

                font-size: 18px;
                padding-bottom: 12px;
              }

              .filterGridContainer {
                display: grid;
                grid-template-columns: 50% 50%;

                &.statusGrid {
                  grid-template-columns: auto auto;
                }

                .filterGridItem {
                  display: inline-flex;
                  padding: 6px 0px;

                  .checkboxNames {
                    font-size: 16px;
                    line-height: 20px;
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }

  }


  .participantsBody {
    margin-top: 16px;

    &.userCard {
      margin-top: 0!important;
      // color: black;
      // display: grid;
      // grid-template-columns: 25%;
      // grid-gap: 10px;
    }
    .nameWrapper{
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.4;
      letter-spacing: 0.2px;
      color: #0c222b;
    }

    .coursesHeadText{
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.4;
      letter-spacing: 0.2px;
      color: #0c222b;
    }

    .participantListContainer {
      margin-top: 12px;
      background: white;
      color: black;
      border-radius: 6px;

    }
    .UserListContainer{
      background: white;
    }

    .participantsGrid {
      display: grid;
      grid-template-columns: 44px auto 30% 20%;
      grid-template-rows: auto;
      border-bottom: 1px solid #e5e9eb;

      &.extended {
        grid-template-columns: 42px 2fr 2fr 2fr repeat(3, 1fr) 46px;

        .headItem {
          font-size: 1em!important;
        }

        .bodyItem {

          &.discountCol {
            &.new {
              background: #007a7d;
            }

            &.active {
              background: #42afb6;
            }

            &.pending_paused,
            &.paused {
              background: #fcb414;
            }

            &.pending_canceled,
            &.canceled {
              background: #cb1b4a;
            }

            &.finished {
              background: #cb1b4a;
            }
          }

          &.emailItem {
              line-height: 44px;
              font-size: 1.3em;

              .icon-check-circle {
                color: green;
              }

              .icon-cancel-circle {
                color: red;
              }
          }
        }

        .leftItem {
          text-align: left!important;
        }

        .centerItem {
          text-align: center!important;
          padding: 0!important;
        }






        @media screen and (max-width: 374px) {

          .headItem {
            font-size: 0.8em!important;
            padding-left: 5px!important;
            padding-right: 5px!important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.asc,
            &.desc {
              padding-left: 20px!important;
            }
          }
        }
      }

      .headItem {
        text-align: left;
        line-height: 44px;
        border-bottom: 1px solid $border-gray;
        position: relative;
        padding-left: 20px;

        &.firstCol {
          line-height: 55px;
          padding-left: 0px!important;
          text-align: center;
        }

        &.textItem {
          font-size: 16px;

          &::before {
            @include menu-arrow();
            left: 2px;
            width: 12px;
            height: 12px;
            margin-top: 0!important;
            transform: translateY(-50%) rotate(270deg);
          }

          &.active:before {
            transform: translateY(-50%) rotate(90deg);
          }
        }

        &.headArrow {
          font-size: 20px;

          &.asc {
            &::before {
              @include menu-arrow();
              left: 2px;
              width: 12px;
              height: 12px;
              margin-top: 0!important;
              transform: translateY(-50%) rotate(270deg);
            }
          }

          &.desc {
            &::before {
              @include menu-arrow();
              left: 2px;
              width: 12px;
              height: 12px;
              margin-top: 0!important;
              transform: translateY(-50%) rotate(90deg);
            }
          }
        }
      }

      .bodyItem {
        padding: 10px 0;
        text-align: left;



        &.firstCol {
          text-align: center;
        }

        &.courseCol.textItem,
        &.dateCol.textItem {
          font-size: 16px;
        }

        &.textItem {
          text-align: left;
          font-size: 20px;
          line-height: 24px;
          padding-left: 10px;
          cursor: pointer;

          &.discountCol {
            color: #fff;
            font-size: 16px;

            &.new {
              background: #007a7d;
            }

            &.active {
              background: #42afb6;
            }

            &.pending_paused,
            &.paused {
              background: #fcb414;
            }

            &.pending_canceled,
            &.canceled {
              background: #cb1b4a;
            }

            &.finished {
              background: #cb1b4a;
            }
          }
        }
      }
    }

    .participantDetailItem {
      border-radius: 6px;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, .3);
      margin-bottom: 1em;
      padding: 12px;
      margin-top: 20px;

      .coursesNotificationText {
        padding: 10px 0;
        font-size: 12px;
        opacity: 0.8;
      }

      .backgroundContainer {
        width: 100%;
        min-height: 50px;
        background: white;
        border-radius: 6px;
      }

      .usersPlaceholder {

        .userDetailsWrapper {
          display: inline-flex;

          .userImageWrapper {
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 50%;
            background: $border-gray;
          }

          .userDetailsCenter {
            margin-left: 14px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            .userDetailsNameWrapper {
              font-size: 12px;
              line-height: 16px;

              .userIdWrapper {
                font-size: 10px;
              }
            }

            .userDiscountsNameWrapper {
              font-size: 10px;
              line-height: 16px;
            }
          }
        }

        .userActionContainer {
          margin-top: 24px;

          .userActionWrapper {
            display: grid;
            grid-template-columns: auto auto auto;
            border: 1px solid $border-gray;
            border-radius: 4px;
            cursor: pointer;

            .userActionItem {
              text-align: center;
              border-left: 1px solid $border-gray;
              padding: 8px 0;


              &:first-child {
                border-left: none;
              }

              .userActionButtonWrapper {
                display: inline-flex;

                .action-icons {
                  font-size: 22px;
                }

                .textWrapper {
                  font-size: 14px;
                  line-height: 26px;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

      .commentPlaceholder {

        .commentHeadContainer {
          position: relative;
          width: 100%;
          height: 36px;


          .headText {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
            font-size: 16px;
          }

          .editComment {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            padding: 5px 0;

            .buttonWrapper {
              .buttonText {
                line-height: 20px;
                margin-right: 8px;
                font-size: 16px;
              }
              .buttonIcon {
                font-size: 20px;
              }
            }
          }
        }

        .commentBodyContainer {
          .commentText {
            font-size: 12px;
            padding-top: 16px;

            &.newCommentEditor {
              height: 200px;
              background: #e5e5e5;
              border-radius: 6px;
              border: none !important;
              color: #000000;
              font-size: 12px;
              padding: 12px 8px;
            }
          }
        }
      }

      .commentNotification {
        font-size: 9px;
        margin-top: 10px;
      }

      .coursesHeadText {

      }

      .coursePlaceholder {
        .coursesGrid {
          display: grid;
          grid-template-columns: 30% 25% 20% 25%;
          font-size: 12px;

          &.extend-membership-list {
            grid-template-columns:  20px 30% auto 20% 25%;
          }

          .radio-input {
            align-self: center;
            justify-self: center;
          }

          .coursesGridItem {
            padding: 15px 8px;
          }

          &.headContainer {
            border-bottom: 1px solid $border-gray;

            .coursesGridItem {
              &.active {
                font-weight: 600;
                position: relative;

                &::after {
                  @include menu-arrow();
                  right: 11px;
                  width: 10px;
                  height: 10px;
                  margin-top: 0!important;
                }

                &.asc::after {
                  transform: translateY(-50%) rotate(270deg);
                }

                &.desc::after {
                  transform: translateY(-50%) rotate(90deg);
                }
              }


            }
          }
        }
      }
    }
  }

  .regularCheckbox {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    border: 1px solid $border-gray;
    border-radius: 6px;

    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;

    &.userCheckbox {
      border-radius: 50%;
    }

    &:checked {
      background-color: #a4f48e;
      border: 1px solid #00b75d;
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
      color: #00b75d;

      &::after {
        content: '\2715';
        font-size: 14px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #008766;
      }
    }
  }
}

.buttonWrapper {
  display: inline-flex;
  font-size: 12px;
  cursor: pointer;
}

.participantComment{
  margin-top: 16px;
}

.participantDetailItem {
  .buttonItem {
    display: inline-flex;
    height: 40px;
    padding: 10px 12px;
    font-size: 16px;
    font-weight: 600;
    background: #002538;
    color: white;
    margin: 0 .25em .5em 0;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 6px;

    margin-top: 10px;
  }

  .showDetails {
      padding: 14px 0;
      font-size: 16px;
      cursor: pointer;
  }

  .challengesItem {
    font-size: 15px;
    padding: 8px 15px;
    display: flex;
    justify-content: space-between;

    span {
      margin-right: 8px;

      i {
        font-size: 17px;
      }

      .icon-check-circle {
        color: #17c717;
      }

      .icon-cancel-circle {
        color: #ff0000;
      }
    }
  }

  .allergiesItem {
    font-size: 15px;
    padding: 8px 15px;
    display: flex;
    justify-content: space-between;

    span {
      margin-right: 8px;

      i {
        font-size: 17px;
      }

      .icon-check-circle {
        color: #17c717;
      }

      .icon-cancel-circle {
        color: #ff0000;
      }
    }
  }

  .aboutUserItem.row {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 10px 15px;
    font-size: 15px;

    .inputWrapper {
      .valueWrapper {
        font-weight: 600;
        font-size: 18px;
      }

      .measuresWrapper {
        margin-left: 5px;
      }

    }
  }

  .usersMotivation {
    .motivation_item {
      padding: 10px 20px;
      font-size: 15px;
    }
  }
}




.userCardButton {
  display: block;
  padding: 0 10px;
  margin-top: 12px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 55px;
  background: #002538;
  color: white;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, .3);
  cursor: pointer;

  &:hover {
    background: rgba(#002538, 0.65);
  }
}
