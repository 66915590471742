.action-footer-container {



  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #D1D1D1;
  background: #fff;
  height: 48px;

  &.set-sticky {
      position: sticky;
  }

  .action-footer-placeholder {
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 4px;

    display: flex;
    align-items: center;

    .button-item {
      width: 100%;
      text-align: center;
      align-items: stretch;

      .buttonWrapper {
        display: block;
        font-size: 12px;
        cursor: pointer;

        .buttonIcon {
          font-size: 20px;
        }

        .buttonText {
          line-height: 14px;
          margin-left: 0;
        }
      }
    }

  }
}

// .ActionFooterPlaceholder {
//   position: fixed;
//   left: 0;
//   bottom: 0;
//   width: 100%;
//   border-top: 1px solid #D1D1D1;
//   background: #fff;
//
//   .ActionFooterContainer {
//     position: relative;
//     max-width: 800px;
//     width: 100%;
//     margin: 0 auto;
//     padding: 4px;
//
//     .ActionFooterWrapper {
//       display: grid;
//       grid-template-columns: auto auto;
//       border: 1px solid #D1D1D1;
//       border-radius: 4px;
//
//       &.ShowCourseUser {
//         grid-template-columns: auto auto auto auto;
//         border: none;
//
//         .ActionFooterItem {
//           border-right: none;
//           height: 40px;
//           padding: 2px 4px;
//
//           .buttonWrapper {
//             display: block;
//
//             .buttonIcon {
//               font-size: 20px;
//             }
//
//             .buttonText {
//               line-height: 14px;
//               margin-left: 0;
//             }
//           }
//         }
//       }
//
//       .ActionFooterItem {
//         height: 36px;
//         text-align: center;
//         border-right: 1px solid #D1D1D1;
//         padding: 5px 10px;
//
//         &:last-child {
//           border-left: none;
//         }
//
//         .buttonIcon {
//           font-size: 28px;
//         }
//
//         .buttonText {
//           font-size: 14px;
//           line-height: 28px;
//           margin-left: 4px;
//         }
//       }
//     }
//   }
// }
