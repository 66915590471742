@import "../_colors.scss";

.SalesOverviewContainer {
  padding: 25px 12px 60px 12px;
  font-size: 14px;
  color: $font-color-gray;


  .SalesCommissionsWrapper {
    background: $light-background;
    border-radius: 6px;
    padding: 4px 8px;

    &.ParicipantsNoWrapper {
      margin-top: 12px;
    }
  }


  .GridContainer {
    display: grid;

    &.CommissionsGrid {
      grid-template-columns: auto 120px;
    }

    .GridItem.CommissionsGridItem {
      font-size: 14px;
      line-height: 18px;
      padding: 4px 0;

      &.ValueItem {
        text-align: right;
        font-weight: 600;
      }
    }



  }

  .buttonItemWrapper {
    margin-top: 12px;
    width: 100%;
    height: 44px;
    font-size: 12px;
    line-height: 40px;
    padding: 2px 11px;
    border-radius: 6px;
    background: #e5e9eb;
    color: black;
    position: relative;
    transition: all 0.3s ease;

    &:after {
      @include menu-arrow();
      right: 11px;
      transform: rotate(180deg);
    }
  }

  .SalesBarsChartWrapper {
    margin-top: 12px;
    width: 100%;
  }

  .HeadWrapper {
    font-size: 14px;
    color: $color-black;
  }

  .ChartWrapper {
    background: $light-background;
    border-radius: 6px;
    // width: 100%;
    // height: 150px;
    margin-top: 12px;
    padding: 8px 14px;
  }

  .SalesDonutChartWrapper {
    margin-top: 12px;
    width: 100%;

    .DonutTextContainer {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: rgba($color-black, 0.6);
      margin-top: 14px;
    }

    .DonutChartContainer {
      position: relative;

      .labelWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 32px;
      }
    }
  }



  .ct-label {
    font-size: 12px;
    margin-left: -7px;
  }

  .ct-chart .ct-bar {
    stroke-linecap: round;

    &.ct-bar-color-1 {
      stroke: #000000;
    }

    &.ct-bar-color-2 {
      stroke: #8d7c7b;
    }

    &.ct-bar-color-3 {
      stroke: #b8a9a8;
    }
  }

  .ct-chart {
    text-align: center;

    .ct-legend {
      display: inline-flex;


      .ct-series {
        padding: 2px 10px 2px 16px;
        position: relative;
        font-size: 12px;

        &:before {
          width: 12px;
          height: 12px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          border: 3px solid transparent;
          border-radius: 50%;
        }

        &.ct-bar-color-1:before {
          background: #000000;
        }

        &.ct-bar-color-2:before {
          background: #8d7c7b;
        }

        &.ct-bar-color-3:before {
          background: #b8a9a8;
        }

      }
    }
  }



  .ct-chart-donut {
    overflow: inherit;

    .ct-series-a .ct-slice-donut-solid {
      fill: #8d7c7b;
    }

    .ct-series-b .ct-slice-donut-solid {
      fill: #b8a9a8;
    }
  }

  .ct-line {
    stroke-width: 1px;
  }

  // .ct-legend {
  //   position: relative;
  //   z-index: 10;
  //
  //   li {
  //       position: relative;
  //       padding-left: 23px;
  //       margin-bottom: 3px;
  //   }
  //
  //   li:before {
  //       width: 12px;
  //       height: 12px;
  //       position: absolute;
  //       left: 0;
  //       content: '';
  //       border: 3px solid transparent;
  //       border-radius: 2px;
  //   }
  //
  //   li.inactive:before {
  //       background: transparent;
  //   }
  //
  //   &.ct-legend-inside {
  //       position: absolute;
  //       top: 0;
  //       right: 0;
  //   }
  //
  //   // @for $i from 0 to length($ct-series-colors) {
  //   //     .ct-series-#{$i}:before {
  //   //         background-color: nth($ct-series-colors, $i + 1);
  //   //         border-color: nth($ct-series-colors, $i + 1);
  //   //     }
  //   // }
  // }
}
