@import "../_colors.scss";

.actionContainer {
  display: grid;
  grid-template-columns: auto 92px 100px;
  padding: 24px 0 12px 0;

  .actionItem {
    font-size: 12px;
    line-height: 20px;

    &.right {
      text-align: right;
    }

    .itemTextWrapper {
      display: inline-flex;
    }

    &.DateContainer {
      .DateNavigation {
        margin-bottom: 0;
        border-top: none;
        border-bottom: none;
        line-height: 0;

        &.shared {
          margin: 0!important;

          .textWrapper {
            line-height: 20px;
          }
        }
      }
    }

  }

  [class^="icon-"], [class*=" icon-"] {
    margin-left: 9px;
    font-size: 20px;
  }
}

.listContainer {



  color: $font-color-gray;


  .weekWrapper {
    &.coursesListItem {


      .weekContainer {
        background: $light-background;
        margin-bottom: 20px;
        border-radius: 6px;
        overflow: hidden;
      }

      .monthContainer {
        background: $light-background;
        margin-bottom: 20px;
        border-radius: 6px;
        overflow: hidden;

        .listFooter {

        }
      }
    }
  }

  .listItem {
    .listHead {
      font-size: 14px;
      height: 44px;
      background: $gray-list-head;
      position: relative;

      .listHeadItem {
        max-width: calc(100% - 50%);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &.left {
          left: 12px;
        }

        &.right {
          right: 12px;
        }
      }

      &.monthHead {
        background: #cccccc
      }
    }

    .listFooter {
      font-size: 14px;
      height: 44px;
      background: $gray-list-footer;
      position: relative;
      color: $font-color-gray;
      padding: 8px 12px;
      line-height: 28px;
    }

    .listBody {
      .DayNameContainer {
        font-size: 14px;
        height: 44px;
        position: relative;
        color: $font-color-gray;
        padding: 8px 12px;
        line-height: 28px;
        background: rgba($gray-list-head, 0.4);
      }

      .coursesListGrid {
        height: 44px;
        display: grid;
        grid-template-columns: 70px auto 70px;

        .coursesListGridItem {
          font-size: 12px;
          color: $font-color-gray;
          align-self: center;
          text-align: center;

          &.courseItem {
            text-align: left;
          }
        }
      }
    }
  }
}

.courseCard {

  .backgroundContainer {
    width: 100%;
    min-height: 50px;
    background: $light-background;
    border-radius: 6px;

  }

  .courseDetailsItem {
    padding: 12px;
    border-bottom: 1px solid $border-gray;
    width: 100%;

    &:last-child {
      border-bottom: none;
    }

    .courseDetailsText {
      font-size: 12px;
      line-height: 20px;

      &.typeItem {
        font-size: 12px;
        line-height: 22px;
        text-transform: capitalize;
      }
    }

    .actionHeader {
      width: 100%;
      height: 36px;

      .buttonIcon {
        font-size: 20px;
      }

      .buttonText {
        font-size: 12px;
        line-height: 22px;
        margin-left: 6px;
      }

    }

    .statisticWrapper {
      display: grid;
      grid-template-columns: auto 20%;

      .statisticItem {
        padding: 6px 0;
        font-size: 12px;


        &.value {
          text-align: right;
        }
      }
    }

    .linkWrapper {
      display: grid;
      grid-template-columns: calc(100% - 100px) 100px;
      position: relative;

      .linkText {
        font-size: 10px;
        border-right: 1px solid $border-gray;
        line-height: 20px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .copyLink {
        text-align: right;
        .buttonText {
          font-size: 10px;
          line-height: 20px;
          margin-right: 4px;
        }

        .buttonIcon {
          font-size: 20px;
        }
      }

      .copied {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-white;
        font-size: 10px;
        padding: 6px 8px;
        background: rgba($color-dark-blue, 1);
        z-index: 10;
        border-radius: 4px;
      }
    }

    &.participantsBody {
      padding: 0!important;
    }
  }

  .courseActionWrapper {
    .courseActionWrapper {
      display: grid;
      grid-template-columns: auto auto auto;
      border: 1px solid $border-gray;
      margin-top: 12px;
      border-radius: 6px;

      .courseActionItem {
        padding: 6px 0;
        text-align: center;
        border-right: 1px solid $border-gray;


        &::last-child {
          border-right: none;
        }

        .buttonIcon {
          font-size: 20px;
        }

        .buttonText {
          font-size: 12px;
          line-height: 22px;
          margin-left: 4px;
        }
      }
    }
  }

}

.courseBodyWrapper {
  padding-top: 35px;
}

.courseListContainer {
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 20px;
  font-size: 14px;
}

.CourseListHeader {
  // font-family: NunitoSans;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: 0.23px;
  text-align: center;
  color: #0c222b;
  border-bottom: 1px solid #e5e9eb;
  margin: 1em 0 .5em 0;
}

.coursListItem {
  .headGroupWrapper {
    font-size: 14px;
    line-height: 44px;
    background: rgba($gray-list-head, 0.4);
    padding: 0 10px;
  }

  .coursesListGrid {
    height: 90px;
    display: grid;
    grid-template-columns: 130px auto 70px;
    align-items: center;
    cursor: pointer;
    background: white;

    .coursesListGridItem{
      .description{
        /* font-family: NunitoSans; */
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: 0.2px;
        color: #0c222b;
      }
    }

    .weekItem {
      text-align: center;

      .CurrentDate{
        background: white;
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid #002538;

        .DayWrapper {
          font-size: 14px;
          font-weight: 500;
          text-transform: capitalize;
          @media screen and (max-width: 480px) { font-size: 12px; }
          padding: 10px 0;
          color: white;
          background: #002538;
        }

        .DateWrapper {
          font-size: 12px;
          padding-top: 4px;
          line-height: 24px;
          @media screen and (max-width: 480px) { font-size: 10px; }
        }
      }
    }

    .bookCodeItem {
      font-size: 14px;
      color: black;
      padding: 0 12px;
      text-transform: capitalize;

      @media screen and (max-width: 480px) {
        font-size: 12px;
        padding: 0;
      }
    }

    .placeItem {
      font-size: 12px;
      color: black;
      text-align: center;
    }


  }
}
