.commission-instructor-container {
  padding: 20px 0;

  @media (max-width: 480px) {
    padding: 20px 10px;
  }


  .commission-instructor-list {

    .commission-instructor-item-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;

      @media (max-width: 480px) {
        font-size: 0.8em;
      }

      .commission-instructor-item-details {
        padding: 8px 12px;
        border-bottom: 1px solid rgba(0,0,0,0.15);

        a {
          color: rgb(3, 155, 229);
        }

        @media (max-width: 480px) {
          padding: 4px 8px;
        }
      }

      &.head {
        font-weight: 600;
      }
    }
  }
}


.year_pagination_container {
    padding: 10px 20px;
    width: 100%;
    height: 50px;

    margin-top: 20px;
    text-align: center;

    .year_pagination {
      display: inline-flex;

      .year_pagination_item {
        padding: 10px;

        &.year_left,
        &.year_right {
          cursor: pointer;
        }

        &.current_year {
          font-weight: 600;
        }
      }
    }
}
