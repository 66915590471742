.NavigationGrid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-items: center;
  grid-column-gap: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.4);
  padding: 20px 10px;

  .YearItem {
    font-size: 2rem;
    font-weight: 600;
  }
}

.tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  background: #e0f2f1;
  margin-bottom: 20px;

  .tab_item {
    align-self: center;
    justify-self: center;
    font-size: 1.2em;
    color: #9e9e9e;
    padding: 10px;
    cursor: pointer;

    transition: color 0.3s ease;

    &:hover,
    &.active {
      color: #000;
    }
  }

}

.MapGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);


  .MapItem {
    padding: 8px;
  }
}

.info-meeting-one {
  background-color: #a4f48e;
  color: black;
}
.info-meeting-two {
  background-color: #00b75d;
  color: white;
}
.info-meeting-four {
  background-color: #008766;
  color: white;
}
.info-blacklist {
  background-color: #f24e4e;
  color: white;
}

.MonthName {
  margin-top: 25px;
  text-transform: capitalize;
}

.month-container {
  margin-top: 15px;

  .cm-grid-result {
    display: grid;
    grid-template-columns: 12.5% 1fr;
    margin-bottom: 20px;
  }

  .cm-month-header{
    font-weight: 600;
    background: #eee;
    text-align: left;
    padding: 6px 4px;
  }

  .cm-days-dimension {
    font-size: 0.7em;
    padding: 1vw;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .cm-item-months {
      overflow-x: auto;
      width: 100%;

      .cmm-grid {
        display: grid;
        grid-template-columns: repeat(7, minmax(80px, 1fr));
      }
  }

  .cm-days-dimension {
    font-size: 0.7em;
    padding: 1vw;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    &.date-field {
      position: relative;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    .day-no {
      position: absolute;
      top: 12px;
      left: 12px;
    }

    .meetin-no {
      position: absolute;
      padding: 3px;
      border-radius: 3px;
      background: #733d3d;
      bottom: 6px;
      right: 6px;
      width: 20px;
      text-align: center;
      color: #fff;
    }

    &.date-field[data-meeting-one=true] {
        background-color: #a4f48e;
        color: black;
    }

    &.date-field[data-meeting-four=true] {
        background-color: #008766;
        color: white;
    }

    &.date-field[data-meeting-two=true] {
        background-color: #00b75d;
        color: white;
    }

    &.date-field[data-blacklisted=true] {
        background-color: #f24e4e;
        color: white;
    }

  }

}

.saveButton {
  position: fixed;
  bottom: 20px;
  right: 30px;
  border-radius: 50%;
  background: #002538;
  color: #fff;
  font-size: 1.4em;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
}

.loadingWrapper {
  position: fixed;
  bottom: 20px;
  right: 30px;

  width: 30px;
  height: 20px;
}
