.wbm {
 &-count {
  margin: 22px 0 10px 0;
 }

  &-wrapper {
    position: relative;
    border-radius: 6px;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #e5e9eb;
  }

  &-tname {
    position: fixed;
    z-index: 2;
    width: 90px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #e6e6e6;

    span {
      display: block;
      width: 62px;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (min-width: 480px) {
        width: 92px;
      }
    }
  }

  &-tdata {
    margin-left: 90px;
  }

  @media screen and (min-width: 480px) {
    &-tname {
      width: 120px;
    }

    &-tdata {
      margin-left: 120px;
    }
  }

  table {
    width: 100%;
  }

  tr:nth-child(2) {
    border-bottom: 1px #d5d5d5 solid;
    font-size: 13px;

    th {
      padding-top: 2px;
    }
  }

  th, td {
    padding: 14px 16px;
    text-align: left;
    white-space: nowrap;
  }

  th {
    padding-top: 8px;
    padding-bottom: 8px;

    span[data-sort] {
      position: relative;
      padding-left: 18px;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 12px;
        height: 12px;
        margin-top: -6px;
        background: url('../SysIons/arrow-right.svg') no-repeat center center;
        background-size: contain;
        transform: rotate(90deg);
      }
    }

    span[data-sort="asc"]:after {
      transform: rotate(270deg);
    }

    span[data-active="true"] {
      font-weight: 600;
    }
  }

  th {
    text-align: left;
  }

  td {
    font-size: 12px;
  }

  &-tname th:first-child,
  &-tname td:first-child {
    padding-left: 12px;
  }

  &-tdata th:last-child,
  &-tdata td:last-child {
    padding-right: 12px;
  }

  &-tdata td {
    text-align: right;
  }

  &-weights {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;

    li {
      width: calc(50% - 6px);
      padding: 10px;
      border-radius: 6px;
      font-size: 15px;
      text-align: center;
      background: #e5e9eb;

      &:nth-child(2n+2) {
        margin-left: 12px;
      }

      &:nth-child(n+3) {
        margin-top: 12px;
      }
    }

    span {
      display: block;
    }

    strong {
      display: block;
      font-size: 24px;
      line-height: 48px;
    }

    time {
      display: block;
      margin-top: 4px;
      font-size: 12px;
    }
  }
}

.participantsListWrapper {
  position: relative;
  border-radius: 6px;
  background: #e5e9eb;
  width: 100%;
  max-height: calc(90vh - 200px);
  height: 60vh;
  overflow-y: scroll;
  resize: both;


  table {
    position: relative;
    border: 1px solid #ddd;
    border-collapse: collapse;
  }

  th {
    background-color: #eee;
    position: sticky;
    top: -1px;
    z-index: 2;

    &:first-of-type {
      left: 0;
      z-index: 3;
    }
  }

  tbody tr td:first-of-type {
    background-color: #eee;
    position: sticky;
    left: -1px;
    text-align: left;
  }
}
