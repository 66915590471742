.page-container {
    padding: 6vh 4vw;
}

.file-item-container {
    display: grid;
    grid-template-columns: auto 90px 60px;
    border-bottom: 1px solid rgba(0,0,0, 0.5);

    .grid-item {
        padding: 10px 6px;
        align-self: center;
        justify-self: left;

        &.th_item {
            font-weight: 800;
        }

        &.item-center {
            justify-self: center;
        }

        .buttonText {
            cursor: pointer;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 0.6;
            }
        }
    }

}

.region-filter-container {
  margin-top: 20px;
  margin-bottom: 20px;

  a {
    color: #039be5;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.5;

    }
  }
}

.page-description {
  margin: 20px 0;
  font-size: 1.6rem;
  text-align: justify;
}

.stats-container {
  margin-top: 20px;
  position: relative;
  border-radius: 6px;
  background: #e5e9eb;
  width: 100%;
  max-height: calc(90vh - 200px);
  max-height: 60vh;
  overflow-y: scroll;
  resize: both;

  table {
    position: relative;
    border: 1px solid #ddd;
    border-collapse: collapse;
    // stretch table to scroll horizontally
    width: 150%;
    @media (max-width: 480px) {
      width: 300%;
    }


    th {
      background-color: #eee;
      position: sticky;
      top: -1px;
      z-index: 2;
      padding: 14px 16px;

      &:first-of-type {
          left: 0;
          z-index: 3;
      }
    }

    td {
      font-size: 12px;
      padding: 14px 16px;
      text-align: left;
      white-space: nowrap;
      text-align: center;

      &:first-of-type {
        background-color: #eee;
        position: sticky;
        left: -1px;
        text-align: left;
      }
    }
  }
}
