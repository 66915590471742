.PopupContainer {
  &.is-open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .PopupContentWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
  }
}

.CourseExtendDateContainer {
  position: relative;
  padding: 10px;
  border-radius: 6px;
  background: #fff;

  .calendar-overlay {
    position: relative;
  }

  .calendar {
    position: relative;
    top: inherit;
    left: inherit;
    transform: inherit;
  }

  .defaultDateInput {
    padding: 10px 4px;
    color: rgba(0,0,0, 0.6);
    font-size: 16px;
  }

  .actionsContainer {
    text-align: center;
    padding: 4px;

    .actionsWrapper {
      display: inline-flex;

      .actionsItem {
        padding: 10px 18px;
      }
    }
  }
}
