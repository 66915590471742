.diaryPage {
  margin-top: 20px;
}

.diarySection {
  margin-bottom: 22px;
}

.diarySectionTitle {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
}

.diaryMeasurementsOverview {
  padding: 4px 12px;
  margin-bottom: 12px;
  border-radius: 6px;
  font-size: 13px;
  background: #e5e9eb;

  li {
    position: relative;
    border-bottom: 1px #d5d5d5 solid;
    line-height: 48px;

    &:last-child {
      border-bottom: 0;
    }
  }

  span {
    position: absolute;
    right: 0;
    font-size: 14px;
    color: #8b8b8b;
  }

  strong {
    font-size: 20px;
    font-weight: 600;
    color: #4b4b4b;
  }
}


.diaryTables {
  position: relative;
  height: 398px;
  font-size: 13px;

  tr:first-child {
    border-bottom: 1px #d5d5d5 solid;
  }

  tr:last-child {
    font-weight: 600;
  }

  th {
    text-align: left;
    font-size: 14px;
  }

  th, td {
    padding: 14px;
    text-align: left;
    white-space: nowrap;
  }

  td:empty:after{
    content: "\00a0";
  }

  a {
    display: block;
    // background: url(../../components/ui/SysIons/arrow-right.svg) no-repeat;
    background-size: 100% 12px;
    background-position:  right -61px center;
  }
}

.diaryTableDays {
  position: absolute;
  left: 0;
  width: 160px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #e6e6e6;
}

.diaryTableWrapper {
  position: absolute;
  left: 160px;
  right: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #e5e9eb;
  overflow-y: hidden;
  overflow-x: auto;
}

.diaryTableValues {
  width: 100%;

  th:not(:last-child),
  td:not(:last-child) {
    text-align: right;
  }

  th:not(:last-child) {
    padding-left: 36px;
  }

  th:last-child {
    width: 200px;
  }

  td.regular {

  }

  td.good {
    background: #9bed89;
  }

  td.bad {
    background: #f24e4e;
    color: #e6e6e6;
  }
}

.diaryTableComments {
  display: block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.diaryComments {
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(black, 0.5);

  section {
    background: white;
    width: calc(100% - 24px);
    max-width: 480px;
    border-radius: 6px;
  }

  header {
    position: relative;
    border-bottom: 1px #d5d5d5 solid;
  }

  h2 {
    padding: 12px;
    line-height: 26px;
    font-size: 16px;
    font-weight: 600;
  }

  button {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 26px;
    line-height: 26px;
    font-size: 18px;
    text-align: center;
    color: #00b75d;
  }

  article {
    height: 360px;
    padding: 18px 12px;
    font-size: 15px;
    line-height: 1.5;
    overflow-y: auto;
    white-space: initial;
  }
}

.diaryBoxes {
  display: flex;
  margin: 0 -6px 12px -6px;
  text-align: center;
  font-size: 13px;

  li {
    flex: 1;
    padding: 12px 0;
    margin: 0 6px;
    border-radius: 6px;
    background: #e5e9eb;

    &[data-featured="true"] {
      background: #e6e6e6;
    }
  }

  span {
    display: block;
    padding: 8px 0 2px 0;
    line-height: 32px;

    &.minmax_wrapper {
      padding: 0;
      line-height: 18px;
      font-size: 10px;
    }
  }

  em {
    color: #8b8b8b;
  }

  strong {
    font-size: 18px;
    font-weight: 600;
    color: #4b4b4b;
  }

  i {
    font-size: 16px;
  }

  .regular {
    background: rgba(249, 235, 69, 0.6)!important;
    color: #000000;
    text-rendering: optimizelegibility;
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.8);

    em {
      color: #000000;
    }

    span {
      color: #000000;

      strong {
        color: #000000;
      }
    }
  }

  .inrange {
    background: rgba(164, 244, 142, 1)!important;
    color: #000000;
    text-rendering: optimizelegibility;
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.8);

    em {
      color: #000000;
    }

    span {
      color: #000000;

      strong {
        color: #000000;
      }
    }
  }

  .passed_max {
    background: rgba(242, 78, 78, 0.7)!important;
    color: #000000;
    text-rendering: optimizelegibility;

    em {
      color: #000000;
    }

    span {
      color: #000000;

      strong {
        color: #000000;
      }
    }
  }
}

.diaryMeals {
  margin-bottom: 12px;
  border-radius: 6px;
  background: #e5e9eb;
  overflow: hidden;

  h3 {
    position: relative;
    padding: 12px;
    line-height: 1.5;
    font-size: 15px;
    font-weight: 600;
    background: #e6e6e6;

    span {
      position: absolute;
      right: 12px;
    }

    em {
      font-weight: normal;
      color: #8b8b8b;
    }
  }
}

.diaryMealItems {
  font-size: 13px;
  padding: 0 12px;

  li {
    position: relative;
    padding: 0 40px 10px 0;
    margin: 10px 0;
    line-height: 1.5;
    border-bottom: 1px #d5d5d5 solid;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .dmiRow {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    em {
      padding-right: 4px;
      font-style: italic;
      color: #8b8b8b;
    }
  }

  .dmiFeature {
    padding-left: 6px;

    &:before {
      content: "•";
      padding-right: 6px;
    }
  }

  .dmiKcal {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 600;
  }
}

.diaryActivities {
  margin-bottom: 12px;
  border-radius: 6px;
  line-height: 1.5;
  font-size: 13px;
  background: #e5e9eb;
  overflow: hidden;

  h3 {
    position: relative;
    padding: 12px;
    line-height: 1.5;
    font-size: 15px;
    font-weight: 600;
    background: #e6e6e6;

    span {
      position: absolute;
      right: 12px;
    }

    em {
      font-weight: normal;
      color: #8b8b8b;
    }
  }

  ul {
    padding: 0 12px;
  }

  li {
    position: relative;
    padding: 0 40px 10px 0;
    margin: 10px 0;
    border-bottom: 1px #d5d5d5 solid;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .daRow {
    display: block;
  }

  .daIntensity {
    padding-left: 6px;
    font-style: italic;
    color: #8b8b8b;

    &:before {
      content: "•";
      padding-right: 6px;
    }
  }

  .daKcal {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 600;
  }
}

.diaryUser {
  margin-bottom: 20px;
}
