.Wrapper {
    max-width: 1170px;
    margin: 0 16px;

    @media screen and (min-width: 768px) {
        margin: 0 24px;
    }

    @media screen and (min-width: 1210px) {
        margin: 0 auto;
    }
}