@mixin group() {
    content: "";
    display: table;
    clear: both;
}

/*
.wrapper {
    max-width: 1170px;
    margin: 0 16px;

    @media screen and (min-width: 768px) {
        margin: 0 24px;
    }

    @media screen and (min-width: 1210px) {
        margin: 0 auto;
    }
}

.section {
    padding: 36px 0;
}
*/

/*
 * Page wrapper

.wrapper {
    max-width: 1170px;
    margin: 0 16px;

    &--slider {
        margin: 0;
    }
}

.section {
    padding: 36px 0;
}


@media screen and (min-width: 768px) {

    .wrapper {
        margin: 0 24px;

        &--slider {
            margin: 0 14px;
        }
    }
}

@media screen and (min-width: 1210px) {

    .wrapper {
        margin: 0 auto;
    }
}
*/


/*
 * Prevent page scrolling
 */
.no-scroll {
    overflow-y: hidden;
}


/*
 * 2 column grid
 */

/*
@media screen and (min-width: 768px) {

    .col-2 {
        float: left;
        width: calc(50% - 15px);

        &:last-child {
            float: right;
        }
    }
}
*/
