.wcwr {
  .loading_container {
    margin-top: 20px;
  }

  &-table {
    overflow: hidden;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-top: 20px;
  }

  &-tr {
    display: flex;
  }

  &-tr:nth-child(1) {
    background: #ccd3d7;
    font-size: 14px;
    color: black;
  }

  &-tr-link {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: rgba(#e5e9eb, 0.65);
    }

    &[data-status="scheduled"] {
      opacity: 0.4;
      pointer-events: none;
    }

    &[data-status="submitted"] {
      font-weight: bold;
    }
  }

  &-day {
    width: 100%;
    padding: 14px 10px;
    background: rgba(#ccd3d7, 0.8);

    strong {
      margin-right: 6px;
      font-weight: bold;
    }
  }

  &-th,
  &-td {
    width: 50%;
    padding: 14px 10px;
    text-align: left;
  }

  &-th:first-child,
  &-td:first-child {
    width: 100%;
  }

  &-th:last-child,
  &-td:last-child {
    width: 248px;
  }

  &-th:last-child,
  &-td:last-child {
    position: relative;
    padding-right: 30px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      display: block;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      background: url('../SysIons/arrow-right.svg') no-repeat center center;
      background-size: contain;
    }
  }

  &-th[data-last]:last-child,
  &-td[data-last]:last-child {
    padding-right: 50px;
  }

  &-th:last-child:after {
    background: none;
  }

  &-td {
    span[data-type],
    span[data-frequency],
    span[data-instructor],
    span[data-status] {
      display: inline-block;
      width: 90px;
      padding: 6px 8px;
      border-radius: 3px;
      font-size: 13px;
      font-weight: normal;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: white;
    }
    span[data-frequency] {
      width: 60px;
    }
    span[data-type="intro"] {
      background: #6eae40;
    }
    span[data-type="paid"] {
      background: #ffc100;
    }
    span[data-type="paid_last"] {
      background: #ef7d22;
    }
    span[data-frequency="4"] {
      background: #a6a6a6;
    }
    span[data-frequency="2"] {
      background: #ffc100;
    }
    span[data-frequency="1"] {
      background: #ef7d22;
    }
    span[data-instructor="-"] {
      background: #ef7d22;
    }
   span[data-instructor="Påbegynt"] {
      background: #ffc100;
    }
    span[data-instructor="Klar"] {
      background: #6eae40;
    }
    span[data-instructor="Levert"] {
      background: #538330;
    }
    span[data-status="scheduled"] {
      background: #ef7d22;
    }
    span[data-status="open"] {
      background: #ef7d22;
    }
    span[data-status="open_started"] {
      background: #ffc100;
    }
    span[data-status="submitted"] {
      background: #6eae40;
    }
    span[data-status="closed"] {
      background: #538330;
    }
  }

  &-section {
    padding-bottom: .5rem;
    margin-top: 2rem;

    font-size: 18px;
    counter-reset: question;
    margin-bottom: 1rem;



  }

  &-grid {
    @media screen and (min-width: 768px) {



    display: grid;
    grid-template-columns: auto 300px;
    > div{
      margin: 1rem;
    }
  }


  }

  &-feedback {
    @media screen and (min-width: 768px) {

    }


  }

  &-section,
  &-feedback {
    h3 {
      font-weight: 600;
      color: black;
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1.1rem;
      margin-top: 2rem;
    }

    label {
      font-weight: 600;
      color: black;
      margin: .25rem;
    }

  }
  &-answer {
    margin-top: 0.8rem;
  }
  &-question {
    position: relative;
    padding-left: 24px;
    margin-top: 1.5rem;
    font-size: 15px;

    &::before {
      counter-increment: question;
      content: counter(question) ".";
      position: absolute;
        left: 0;
        top: 0;
      font-weight: 600;
      color: black;
    }

    h4 {
      margin: 10px 0;
      font-weight: 600;

      color: black;
    }

    & > div {
      white-space: pre-line;
    }
  }

  &-feedback {
    margin-top: 16px;
  }

  .button {
    padding: 10px 14px;
    margin: 16px 16px 0 0;
    border-radius: 4px;
    border: 1px #002538 solid;
    background: white;
    color: #002538;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.9;
    }

    &[data-loading="true"] {
      opacity: 0.3;
      pointer-events: none;

      &:after {
        content: ' ...';
      }
    }

    &-primary {
      background: #002538;
      color: white;
    }
  }

  &-message {
    padding: 12px;
    margin-top: 20px;
    border-radius: 6px;
    background: #f1c40f;
  }

  &-user-info {
    padding: 16px;
    border-radius: 6px;
    background: #f6f6f6;

    &-wrapper {
      position: relative;
      margin-top: 20px;
    }

    &[data-sticky="true"] {
      position: fixed;
      z-index: 1;
      top: 74px;
      width: 100%;
      max-width: 95vw;

      box-shadow: 0px 0px 14px 0px #ccd3d7;

      @media screen and (max-width: 1199px) {
        max-width: 94vw;
      }

      @media screen and (max-width: 991px) {
        max-width: 93vw;
      }

      @media screen and (max-width: 767px) {
        max-width: 90vw;
      }
    }

    li {
      display: inline-block;
      padding: 0 20px 12px 0;

      span {
        display: block;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        color: #999;
      }
    }

    .user {
      position: relative;
      display: block;

      a {
        text-decoration: underline;
        color: #002538;

        &.name_copy_button {

          padding: 0 10px;

          border-radius: 6px;
          font-size: 14px;
          background: #002538;
          color: white;
          box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
          cursor: pointer;
          text-decoration: none;
          margin-left: 1em;
        }
      }

      strong {
        padding-right: 12px;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .toggleInfo {
      position: absolute;
        top: -10px;
        right: -8px;
      width: 40px;
      height: 40px;
      transform: rotate(90deg);
    }

    &[data-open="true"] {
      li:not(:first-child):not(:last-child) {
        display: inline-block;
      }
      .toggleInfo {
        transform: rotate(90deg);
      }
    }

    &[data-open="false"] {
      li:not(:first-child) {
        display: none;
      }
      li:first-child {
        padding-bottom: 0;
      }
      .toggleInfo {
        transform: rotate(-90deg);
      }
      .actions {
        display: none!important;
      }
      .comment {
        display: none!important;
      }
    }

    .actions {
      display: block!important;
      padding: 12px 0 0 0;
      border-top: 1px #ccd3d7 solid;
    }

    .comment {
      display: block!important;
      padding: 12px 0;
      border-top: 1px #ccd3d7 solid;

      .text {
        white-space: pre-line;
        text-align: justify;
      }

      form[data-loading="true"] {
        opacity: 0.6;
        pointer-events: none;
      }

      textarea {
        width: 100%;
        padding: 12px;
        margin-top: 4px;
        border-radius: 6px;
        border: 0;
        background: white;
        font-family: inherit;
        font-size: inherit;
      }

      button {
        margin: 8px 20px 0 0;
        font-weight: 700;
        color: #002538;
      }
    }
  }

  &-widget-wrapper {
    padding-top: 16px;
  }

  &-widget {
    display: flex;
    margin-bottom: 18px;
    ol{
      list-style: decimal;
      list-style-position: inside;
      font-size: 1.2rem;
      line-height: 1.4rem;
      li{
        margin-bottom: 1rem;
      }
    }


    section {
      width: 50%;
      padding: 30px 24px;
      border-radius: 10px;
      font-size: 14px;
      background: #002538;
      color: white;

      &:first-child {
        margin-right: 12px;
      }
      &:last-child {
        margin-left: 12px;
      }
      h4 {
        padding-left: 4px;
        margin-top: 8px;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      table {
        width: 100%;
        color: inherit;
        font-size: inherit;
        border-collapse: collapse;
      }
      thead th {
        border-bottom: 1px #ccd3d7 solid;
      }
      tfoot td {
        border-top: 1px #ccd3d7 solid;
      }
      tbody tr:not(:last-child) td {
        border-bottom: 1px #385463 solid;
      }
      th, td {
        padding: 10px 4px;
      }
      th:first-child {
        text-align: left;
      }
      th:not(:first-child), td:not(:first-child) {
        text-align: right;
      }
      td:first-child {
        font-weight: 300;
      }
      th, td:not(:first-child) {
        font-weight: 700;
      }
      ul {
        margin-bottom: 24px;
      }
      li {
        position: relative;
        padding: 10px 64px 10px 4px;
        font-weight: 300;
      }
      li:not(:last-child) {
        border-bottom: 1px #385463 solid;
      }
      strong {
        position: absolute;
        top: 10px;
        right: 4px;
        font-weight: 700;
      }
      b {
        font-weight: 700;
      }
    }
  }
}

.widget-food[data-featured='false'] {
  background-color: #f1f1f1;
  color: #002538;
}

.widget-food h4 {
  padding-left: 4px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.widget-food h4 span {
  position: relative;
  top: 1px;
  font-size: 14px;
  font-weight: 300;
  text-transform: initial;
  letter-spacing: 0;
}
.widget-food[data-featured='false'] {
  background-color: #f1f1f1;
  color: #002538;
}
.widget-food li {
  position: relative;
  padding: 10px 48px 10px 4px;
  font-size: 15px;
  font-weight: 300;
}
.widget-food li:not(:last-child) {
  border-bottom: 1px #385463 solid;
}
.widget-food[data-featured='false'] li:not(:last-child) {
  border-bottom: 1px #ccd3d7 solid;
}
.widget-food[data-featured='true'] tbody tr:not(:last-child) td {
  border-bottom: 1px #385463 solid;
}
.widget-food h5 {
  margin-bottom: 4px;
  font-size: inherit;
  font-weight: 400;
}
.widget-food div[data-progressbar] {
  height: 8px;
  margin-top: 2px;
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: #c3ccd0;
}
.widget-food div[data-progressbar] div {
  height: 100%;
  border-radius: 4px;
}
.widget-food div[data-progressbar='good'] div {
  background: linear-gradient(to left, #9eef89 0%, #026675 100%);
}
.widget-food div[data-progressbar='bad'] div {
  background: linear-gradient(to left, #f24e4e 0%, #f89c46 100%);
}
.widget-food div[data-face] {
  position: absolute;
  top: 50%;
  right: 4px;
  width: 30px;
  height: 30px;
  margin-top: -15px;
}
.widget-food strong[data-recommended] {
  position: relative;
  top: auto;
  right: auto;
  display: block;
  font-size: 14px;
  font-weight: 700;
}
.widget-food strong[data-recommended]:after {
  content: attr(data-recommended);
  position: absolute;
  right: 0;
  font-weight: 300;
}
.widget-food div[data-images] {
  display: flex;
  justify-content: space-between;
  width: 194px;
  margin-top: 2px;
  margin-bottom: 4px;
}
.widget-food div[data-images='heart'] {
  width: 36px;
}
.widget-food div[data-images] img {
  width: 16px;
}
.widget-food div[data-images='vitamin'] img {
  width: 24px;
}
.widget-food strong span {
  font-weight: 300;
}
.widget-food li strong {
  position: initial;
}

.help-link {
  font-size: 14px;
  text-decoration: underline;
  color: #0056b3;
}
.help-link:hover {
  text-decoration: none;
}

.first-membership {
  margin-left: 12px;
}

.started {
  display: inline-block;
  padding: 0 10px;
  margin-left: 8px;
  border-radius: 8px;
  line-height: 16px;
  font-size: 9px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  background: #eee;
  color: #555;
}
#feedback-form .started {
  padding: 0 14px;
  margin-left: 0;
  border-radius: 12px;
  line-height: 24px;
  font-size: 12px;
}
