.WeightModalForm {
  width: 90vw;
  max-height: 90vh;
  min-height: 45vh;
  overflow-y: auto;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: calc(50% + 50px);
  left: 50%;
  transform: translate(-50%, -50%);

  &.ParticipantForm {
    footer {
      height: auto !important;
      bottom: 0;
      position: absolute;
      width: 100%;
      padding: 10px 20px;

      button {
        display: block;
        float: right;
        height: 40px;
        padding: 10px 12px;
        margin-right: -12px;
        font-size: 16px;
        font-weight: 600;
        background: #002538;
        color: white;
        margin: 0 .25em .5em 0;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 6px;
        transition: opacity 0.3s ease;


        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .strong {
    font-weight: 600;
  }

  .timeClean {
    font-size: 13px;
  }

  .ModalHeader {
    width: 100%;
    display: grid;
    grid-template-columns: 75px auto 75px;

    &.weighing {
      grid-template-columns: 110px auto 110px;

      .close_button {
        font-size: 18px;
        background: #002538;
        color: #ffffff;
        padding: 8px;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
        transition: opacity 0.3s ease;
        border-radius: 4px;

        &:hover {
          opacity: 0.5;
        }


        .textWrapper {
          white-space: nowrap;
        }
      }

      .cancel_button {
        background: #776161;
      }
    }

    .header_item {
      text-align: center;
      padding: 15px 10px 15px 10px;

      .close_button {
        display: inline-flex;
        cursor: pointer;
        font-size: 20px;

        .textWrapper {
          font-size: 14px;
          line-height: 20px;
          margin-left: 5px;

          @media (max-width: 480px) {
            font-size: 10px;
          }
        }
      }
    }
  }

  .ModalBody {

    .header {
      width: 100%;
      display: grid;
      grid-template-columns: 50% 50%;
      overflow: hidden;
      margin-bottom: 20px;
      padding-bottom: 2px;

      .left-item {

        p {
          float: left;
        }

        time {
          float: right;
          margin-top: 18px;
          font-size: 13px;
        }
      }

      .right-item {
        justify-self: end;
      }


    }

    .list-wrapper {
      margin-bottom: 20px;
    }

    .radio-grid-container {
      display: grid;
      grid-template-columns: 40px 2fr 1fr 1fr;

      border-bottom: 1px solid #e5e9eb;
      font-size: 18px;
      grid-template-rows: 44px;

      align-items: center;
      justify-items: left;

      &.radio-list-header {
        border-bottom: 1px solid #e5e9eb;
        justify-items: center;
        font-size: 20px;
        font-weight: 600;


      }

      @media (max-width: 480px) {
        grid-template-columns: 20px 2fr 1fr 1fr;
        font-size: 14px;

        grid-template-rows: 60px;

        &.radio-list-header {
          font-size: 16px;
        }
      }
    }

    .radio-input-label {
      margin-left: 10px;
    }

    select {
      display: block;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      background-color: transparent;
      outline: none;
      height: 3rem;
      width: 100%;
      font-size: 16px;
      margin: 0 0 8px 0;
      padding: 0;
    }

    .Fieldset {
      width: 100%;
      display: block;
      padding: 20px 40px;

      label {
        width: 100%;
        color: #9e9e9e;
        font-size: 1rem;
        cursor: text;
      }

      input {
        border-bottom: 1px solid rgba(0,0,0,0.5);
        background-color: transparent;
        outline: none;
        height: 3rem;
        width: 100%;
        font-size: 16px;
        margin: 0 0 8px 0;
        padding: 0;
      }

    }

    .heading-container {
      margin-bottom: 10px;
    }

    .table-container {
      margin-bottom: 20px;
      height: 25vh;
      overflow: auto;
      
      table {
        border-collapse: collapse;
        width: 100%;

        th {
          padding: 10px;
          border: 1px solid #ddd;

          text-align: left;
          background-color: #018766;
          color: white;
        }
        td {
          padding: 10px;
          border: 1px solid #ddd;
        }

        tr:nth-child(even){
          background-color: #f2f2f2;
        }

        tr:hover {
          background-color: #ddd;
        }
      }
    }

    .search_container {
      label {
        width: 100%;
        color: #9e9e9e;
        font-size: 1rem;
        cursor: text;
      }

      input {
        border-bottom: 1px solid rgba(0,0,0,0.5);
        background-color: transparent;
        outline: none;
        height: 3rem;
        width: 100%;
        font-size: 16px;
        margin: 0 0 8px 0;
        padding: 0;
      }
    }

    .substitute_list {
      padding: 20px 10px;
      height: 25vh;
      overflow: auto;

      .substitute_list_item {
        padding: 5px 0;
        line-height: 1.2rem;

        label {
          font-size: 1rem;
          cursor: pointer;
        }
        input {
          width: 1.2rem;
          height: 1.2rem;
          margin-right: 20px;
        }
      }
    }
  }

  footer {
    padding: 0 0 20px 0;
    height: auto!important;

    &.display-flex {
      display: flex;
      padding: 20px 0;

      .buttonContainer {
        width: 50%;
      }
    }

    .ShowSummary {
      position: relative;
      display: inline-block;
      padding: 0 10px;
      margin-top: 12px;
      border-radius: 6px;
      font-size: 18px;
      line-height: 55px;
      background: #a4f48e;

      color: #000;
      box-shadow: 1px 2px 4px rgba(0,0,0,.3);
      cursor: pointer;
    }

    .openWeight {
      padding: 0 0 0 10px;

      .seeDevelopment {
        display: inline;
        padding: 10px 14px;
        margin-top: 0px;
        line-height: 45px;
      }
    }
  }

  .SummeryWrapper {
    .SummaryDetails {
      padding: 10px 20px;

      .sw-item {
        margin-top: 12px;
        border-radius: 6px;
        background: #e5e9eb;

        .wdb {
          display: grid;
          grid-template-columns: auto 15%;
          font-size: 13px;
          padding: 0 12px;

          .wdb-item {
            padding: 14px 0;
            text-align: left;

            &.right {
              text-align: right;
            }
          }
        }
      }


    }
  }

}

.MessagesModalForm {
  width: 90vw;
  padding: 20px 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  max-height: 95vh;
  display: flex;
  flex-direction: column;

  .ModalHeader {
    display: inline-grid;
    grid-template-columns: 75px auto 75px;
    width: 100%;

    .header_item {
      display: inline-flex;
      align-self: center;
      justify-self: center;

      .action-icons {
        font-size: 20px;
      }

      .textWrapper {
        font-size: 14px;
        line-height: 20px;

        &.margin_left {
          margin-left: 4px;
        }

        &.margin_right{
          margin-right: 4px;
        }
      }
    }
  }

  .ModalBody {
    .item-container {

      margin-top: 12px;

      .email-tags {
        width: 100%;
        background: #e5e5e5;
        border-radius: 4px;
        padding: 0 6px;
        display: grid;
        grid-template-columns: auto;
        font-size: 14px;


        &.two-columns {
          grid-template-columns: 30px auto;
        }

        .to-text-container {
          box-sizing: border-box;
          margin: 6px 6px 0 0;
          padding: 6px 2px;

          &.hidden {
            display: none;
          }
        }
        .react-tags {
          position: relative;
          width: 100%;
        }

      }

      .subject {
        background: #e5e5e5;
        border-radius: 6px;
        color: #000000;
        font-size: 12px;
        padding: 4px 8px;
        border: none;
        width: 100%;
        height: 30px;
      }

      &.message-container {


        [contenteditable=true]:empty:before {
          content: attr(placeholder);
          display: block;
          color: #aaa;
        }

        .messageText {
          width: 100%;
          height: 100%;
          min-height: 20vh;
          background: #e5e5e5;
          border-radius: 6px;
          color: #000000;
          font-size: 12px;
          padding: 12px 8px;
          outline: none;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 40vh;
          border: none;
          resize: none;

          b {
            font-weight: bold;
          }

          i {
            font-style: italic;
          }

          ol {
            margin-left: 20px;
            list-style: decimal;
          }

          ul {
            margin-left: 20px;
            list-style: disc;
          }
        }
      }

      .userNameWrapper {
        width: 100%;
        padding: 8px 0 9px 0;
        border-bottom: 1px solid #d7d7d7;

        .toTextWrapper {
          font-size: 10px;
          float: left;
          margin-right: 3px;
        }

        .nameItem {
          font-size: 10px;
          float: left;
          margin-right: 3px;


          &::after {
            content: ",";
          }

          &:last-child::after {
            content: "";
          }
        }
      }


      .actionButtons {
        position: relative;
        width: 100%;
        height: 30px;
        margin-top: 8px;
        margin-bottom: 8px;

        button {
          font-size: 14px;
          font-weight: 800;
          color: #000;
          padding: 6px;
          background: #e5e5e5;
          border-radius: 6px;
          margin-right: 10px;
        }

        .emailContentAction {
          &.attachment {
            right: 0;
            padding: 4px 2px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            .buttonWrapper {
              display: inline-flex;
              font-size: 12px;
              cursor: pointer;

              .action-icons {
                font-size: 20px;
              }

              .textWrapper {
                line-height: 20px;
                font-size: 14px;
                margin-left: 5px;
              }

            }
            .uploadInpput {
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .SmsCountContainer {
        text-align: right;
        padding: 0px 10px 5px 10px;

        .SmsCountWrapper {
          display: inline-flex;
          font-size: 12px;

          .SmsCountItem {
            margin-left: 5px;
          }
        }
      }
    }

    .attachmentContainer {
      width: 100%;
      text-align: left;

      .attachmentWrapper {

        .countAttachments {
          font-size: 10px;
          display: inline-block;
          margin-right: 3px;
        }

        .attachmentsText {
          display: inline-block;

          .attachmentItems {
            font-size: 10px;
            display: inline-block;
            margin-right: 3px;
            white-space: nowrap;

            i {
              position: relative;
              top: 2px;
              font-size: 13px;
              cursor: pointer;
            }

            &::after {
              content: ",";
            }

            &:last-child::after {
              content: "";
            }
          }
        }
      }
    }

  }
}

.weightbook_container {

    margin-top: 15px;

    .bold_item {
        font-weight: 600!important;
    }

    .weightbook_item {
        margin-bottom: 5px;
        display: grid;
        grid-template-columns: 2fr 1fr;

        .left_item {
            justify-self: left;
        }

        .right_item {
            justify-self: right;
        }
    }
}
