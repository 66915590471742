.DateNavigation.shared {
    max-width: 1170px;
    margin-top: 4rem;
    margin-left: 16px;
    margin-right: 16px;

    @media screen and (min-width: 768px) {
        margin-top: 5.4rem;
        margin-left: 24px;
        margin-right: 24px;
    }

    @media screen and (min-width: 1210px) {
        margin-left: auto;
        margin-right: auto;
    }
}

.calendar {
  .calendar-time {
    .time-container {
      padding: 1.6rem 0 0 0;

      .time-item {
        width: 100%;
        max-width: 60px;
        margin: 0;
        padding: 3px 4px;
      }
    }
  }
}
