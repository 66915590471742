@mixin menu-arrow() {
     content: '';
     position: absolute;
     top: 50%;
     right: 0;
     display: block;
     width: 16px;
     height: 16px;
     margin-top: -8px;
     background: url('./arrow.svg') center center no-repeat;
     background-size: contain;
}

@import '_reset.scss';
@import '_colors.scss';
@import '_foundation.scss';
@import '_icons.scss';
@import '_utils.scss';
@import 'ui/ActionFooter/styles.scss';
@import 'ui/DateNavigation/styles.scss';
@import 'ui/DateNavigation/styles-1.scss';
@import 'ui/Header/styles.scss';
@import 'ui/Loading/styles.scss';
@import 'ui/LoginModal/styles.scss';
@import 'ui/Menu/styles.scss';
@import 'ui/ModalOverlay/styles.scss';
@import 'ui/Modals/styles.scss';
@import 'ui/PopupContainer/styles.scss';
@import 'ui/UserComment/styles.scss';
@import 'ui/Wrapper/styles.scss';
@import 'ui/UsersMemberships/styles.scss';
@import 'page/Assistant.scss';
@import 'page/HomePage.scss';
@import 'page/Participants.scss';
@import 'page/Course.scss';
@import 'page/Email.scss';
@import 'page/SalesOverview.scss';
@import 'page/SMS.scss';
@import 'page/WeightBook.scss';
@import 'page/WeightBookForm.scss';
@import 'page/WeightBookResult.scss';
@import 'page/WeightBookMultiple.scss';
@import 'page/Diary.scss';
@import 'page/RoedeCourseCalendar.scss';
@import 'page/RoedeCourseDetails.scss';
@import 'page/RoedeCourse.scss';
@import 'page/CoursePlanner.scss';
@import 'page/CommissionInstructor.scss';
@import 'page/WebCourseWeekReports.scss';
@import 'page/Page.scss';

.loading_wrapper {
  padding: 20px 0;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clear {
  clear:both;
  height:0;
  margin:0;
  padding: 0px !important;
  font-size: 1px;
  line-height: 0;
}

.html .login {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 370px;
  padding: 16px;
  margin: -185px 0 0 -150px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background: #ffffff;
  display: none; }
.html .login__header {
  position: relative;
  height: 92px;
  padding-top: 30px;
  text-align: center; }
.html .login__logo {
  width: 166px;
  height: 39px; }
.html .login__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  color: #fff;
  background: #ffffff; }
.html .login__close:after {
  content: '\e9fa';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  line-height: 32px;
  font-family: 'Simple-Line-Icons-Pro';
  font-size: 24px;
  color: #00b75d; }
.html .login__form {
  margin-bottom: 20px; }
.html .login__field {
  width: 100%;
  height: 48px;
  padding: 0 14px;
  margin-bottom: 16px;
  border: 1px #cecece solid;
  border-radius: 6px; }
.html .login__button {
  float: right;
  background: #ffffff;
  color: #00b75d; }
.html .login__footer {
  color: #00b75d; }
.html .login__footer p {
  margin-bottom: 16px; }
.html .login__footer p:last-child {
  font-size: 14px; }
.html .login.is-open {
  display: block; }

  .headWrapper {
    padding-bottom: 23px;
    font-size: 14px;
    .headText {
      font-size: 22px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.27;
      letter-spacing: 0.2px;
      text-align: center;
      color: black;
      margin-bottom: 20px;
    }
    .breadcrumbsWrapper {
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: black;
      margin-top: 16px;

    }
  }

  .react-tags-container {
    position: relative;
    width: 100%;
    height: 100%;

    .react-tags__selected {
      display: inline;


      .react-tags__selected-tag {
        display: inline-block;
        box-sizing: border-box;
        margin: 6px 6px 0 0;
        padding: 6px 8px;
        border: 1px solid #D1D1D1;
        border-radius: 2px;
        background: #e5e9eb;
        font-size: inherit;
        line-height: inherit;
      }
    }

    .react-tags__search {
      display: inline-block;
      padding: 7px 2px;
      margin-bottom: 6px;
      max-width: 100%;

      input {
        background: transparent;
      }
    }

    .react-tags__suggestions {
      position: absolute;
      top: 100%;
      left: -28px;
      background: #fff;
      width: calc(100% + 28px);
      z-index: 10;

      ul {
        margin: 4px -1px;
        padding: 0;
        list-style: none;
        background: white;
        border: 1px solid #D1D1D1;
        border-radius: 2px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      }

      li {
        border-bottom: 1px solid #ddd;
        padding: 6px 8px;
      }

      li mark {
        text-decoration: underline;
        background: none;
        font-weight: 600;
      }

      li:hover {
        cursor: pointer;
        background: #eee;
      }

      li.is-active {
        background: #b7cfe0;
      }

      li.is-disabled {
        opacity: 0.5;
        cursor: auto;
      }
    }

  }

  .disabled {
    opacity: 0.5;
  }


.ct-chart .ct-label.ct-horizontal.ct-end {
  font-size: 12px!important;
  font-weight: 600!important;
  // display: inline-block;
  // width: auto !important;
  // transform: translateX(-50%);
}

.ct-chart .ct-label.ct-horizontal {
  text-anchor: middle;
}

.ct-label.ct-vertical.ct-end {
  font-size: 12px!important;
  font-weight: 600!important;
}

.regularCheckbox {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;

    &.userCheckbox {
        border-radius: 50%;
    }

    &:checked {
      background-color: #a4f48e;
      border: 1px solid #00b75d;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
      color: #00b75d;

      &::after {
        content: '\2715';
        font-size: 14px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #008766;
      }
    }
}

strong {
  font-weight: 600;
}

.pageTitle {
  width: 100%;
}

.buttonContainer {
  text-align: left;

  &.pullRight {
    text-align: right;
  }

  .buttonWrapper {
    display: inline-flex;

    .buttonItem {
      border-radius: 4px;
      padding: 10px 14px;
      background: #002538;
      color: white;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
      transition: opacity 0.3s ease;

      &.waightbookling {
        background: #ffffff;
        border: 1px solid #002538;
        color: #002538;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

}

.buttonItem {
  border-radius: 4px;
  padding: 10px 14px;
  background: #002538;
  color: white;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease;

  &.waightbookling {
    background: #ffffff;
    border: 1px solid #002538;
    color: #002538;
  }

  &:hover {
    opacity: 0.5;
  }
}

.processing_image {
  height: 10px;
}

.filterGridContainer {
  &.subtypeList {
    margin-top: 5px;
  }
}

.user-action-buttons-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 10px;
  row-gap: 10px;

  .userActionButtonWrapper {
    display: inline-flex;
    padding: 0 10px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 55px;
    color: #002538;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    width: 100%;
    transition: all 0.3s ease;

    .action-icons {
      line-height: 55px;
      font-size: 22px;
    }

    .textWrapper {
      padding-left: 5px;
    }

    &[data-enable=true] {
      pointer-events: none;
      opacity: 0.6;
    }

    &:hover {
      // color: rgba(0,37,56,0.8);
      opacity: 0.5;
    }


  }

  .userCardButton {
    margin-top: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30px;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.copied-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.2);
  z-index: 4;

  .copied-static {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    padding: 10px 20px;
    background: #232b3c;
    z-index: 10;
    border-radius: 4px;
    font-size: 1.5em;
  }
}


#root .navbar{
  background-color: #008766;

}


#root .navbar[data-as-instructor=true]{
  background-color: #f24e4e;

}

#root .navbar-dark .navbar-nav .nav-link{
  color: rgba(255, 255, 255, 0.95);
}

.user-dropdown-menu .dropdown-menu{
  right: 0;
  left: auto;
}
.nettcourse_wrapper {


  & > div {
    display: none;

    &:first-child {
      display: block;
    }
  }

  &.show > div {
     display: block;
  }
}

table {
  th, td {
    white-space: nowrap;
  }
  &.nettcourse_table {
    td {
      vertical-align: middle;
    }
    .align_center {
      text-align: center;
      vertical-align: middle;
    }
    .list-group-item {
      background-color:inherit;
      border:none;

      &.more {
        &::after {
          content: " ...";
        }
      }

      &.status_container {
        color: #fff;

        &.status_active {
          background: #42afb6;
        }

        &.status_paused {
          background: #fcb414;
        }

        &.status_pending_canceled {
          background: #cb1b4a;
        }
      }

    }
  }
}


.email_allowed_container {
  .icon-check-circle {
    color: green;
  }

  .icon-cancel-circle {
    color: red;
  }
}

.autocomplete_container {
  position: relative;
  width: 100%;
  height: auto;
  display: block;

  .autocomplete_item {
    width: 100%;

    &.list_wrapper {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 40px;

    }
  }
}

.participants_filter {
  .form-row {
    margin-bottom: 20px;

    .form-label {
      font-size: 1.5em;
      font-weight: 500;
    }

    .form-check-label {
      margin-left: 10px;
    }
  }
}

.attachefiles{
  margin-bottom: 2rem;
  ul{
    list-style: circle;
    list-style-position: inside;
  }
  li{
    font-size: 1rem;

  }
}

.feedback_attachment_container {
  .feedback_attachment {

    border-bottom: 1px solid rgba(0,0,0, 0.1);


    &.header {
      font-weight: 600;
    }

    .col {
      padding: 8px 20px;
      text-align: center;
      line-height: 22px;

      &.align_left {
        text-align: left;
      }
    }
  }
}

.feedback_attachment.header {
  padding: 4px 0;
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
}
.feedback_attachment.row {
  padding: 4px 0;
}
.feedback_attachment.row:nth-child(2n+1) {
  background-color: #f1f1f1;
}

.file-tags-container {
  margin-bottom: 16px;
}
.file-tags {
  display: inline;
  margin-left: 8px;
}
.file-tags li {
  display: inline;
}
.file-tags li:not(:last-child):after {
  content: ", ";
}
.file-tags button {
  font-size: 16px;
  text-decoration: underline;
  color: #0056b3;
}
.file-tags button:hover {
  text-decoration: none;
}
.file-tags button[data-selected="true"] {
  font-weight: bold;
  text-decoration: none;
}

.remove-border-wrapper {
  div.event-item {
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
  }

  .event-time {
    display: block;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #999;
    margin-bottom: 6px;
  }

  .event-message {
    font-size: 16px;
    font-weight: 700;
  }
}

.membership-nav {
  text-decoration: none!important;
}

.membership-nav.active {
  background: #002538!important;
}

.button-wrapper-center {
  padding: 20px 10px 0;
  text-align: center;

  .buttonItem {
    display: inline-flex;
  }
}

.next-report-week {
  font-size: 16px;
  transition: opacity;
}
.next-report-week[data-loading="true"] {
  opacity: 0.65;
  pointer-events: none;
}
.next-report-week h4 {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: bold;
}
.next-report-week div {
  line-height: 42px;
}
.next-report-week span {
  padding: 0 12px;
}
.next-report-week button {
  display: inline-block;
  width: 42px;
  border-radius: 5px;
  background: #007bff;
  color: white;

  &:disabled {
    opacity: 0.35;
    pointer-events: none;
  }
}

.report_form {
    .form_item {
      margin-top: 2rem;
    }

    .started {
      margin-left:inherit;
    }

    .form-group {
      .form-label {
        margin-bottom: 6px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .preview_button {
      &[data-disabled=true] {
        opacity: 0.35;
        pointer-events: none;
      }
    }
}

.review_page {
  .wcwr-user-info-wrapper {
    margin-top: inherit;
  }

  .preview_item {
    .preview_label {
      margin-bottom: 1rem;
      font-size: 16px;
      font-weight: bold;
    }

    .focus_preview {
      font-size: 17px;
    }

    .attachefiles {
      margin-bottom: 0;
    }

    .feedback_wrapper {
      font-size: 17px;
      text-align: justify;

      strong {
        font-weight: 600;
      }

      em {
        font-style: italic;
      }

      a {
        text-decoration: underline;
        color: #002538;

        &:hover {
          color: #0056b3;
        }
      }

      p {
        margin-bottom: 10px;
      }

      ol, ul {
        list-style: inherit;
        margin-top: 0;
        margin-bottom: 1rem;
        padding-inline-start: 40px;
      }
    }
  }

  #save-button[data-loading="true"] {
    opacity: 0.65;
    pointer-events: none;
  }
}

.container-wrapper {
  max-width: 100%;
  padding: 0;
  margin-bottom: 5px;

  .col {
    line-height: 40px;

    button {
      margin:0px;
    }

    &:last-child {
      text-align: right;
    }
  }
}
