html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    @media screen and (max-width: 374px) { font-size: 53.34%; }

    @media screen and (min-width: 375px) { font-size: 62.5%; }
}

html {
    font: 18px/1.4 'Nunito Sans', Helvetica, Arial, sans-serif;
    color: $color-body-text;
    text-rendering: optimizelegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
    vertical-align: middle;
}

a {
    color: inherit;
}

button,
input {
    overflow: visible;
    padding: 0;
    margin: 0;
    border: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    color: inherit;
    outline: 0;
}

button {
    overflow: visible;
    cursor: pointer;
    background-color: transparent;
    cursor: pointer;
}

input {
    color: $color-title-blue;
}

::-webkit-input-placeholder {
  color: $color-body-text-weak;
}
::-moz-placeholder {
  color: $color-body-text-weak;
}
:-ms-input-placeholder {
  color: $color-body-text-weak;
}
