.wbr {
  &-user {
    display: block;
    position: relative;
    min-height: 76px;
    padding: 12px 12px 12px 78px;
    margin-top: 20px;
    border-radius: 6px;
    font-size: 12px;
    background: #e5e9eb;

    img {
      position: absolute;
      left: 12px;
      top: 12px;
      width: 52px;
      height: 52px;
      border-radius: 50%;
    }

    h2 {
      margin: 4px 0 8px;
      font-size: 15px;

      span {
        margin-left: 4px;
        font-size: 12px;
      }
    }
  }



  &-registration {
    position: relative;
    padding: 12px;
    margin-top: 12px;
    border-radius: 6px;
    font-size: 15px;
    line-height: 20px;
    background: #e5e9eb;

    strong {
      font-weight: 600;
    }

    .modelRegistration {
      position: absolute;
      top: 12px;
      right: 12px;
      padding-right: 24px;
      cursor: pointer;

      i {
        position: absolute;
        top: -1px;
        right: 0;
        font-size: 18px;
      }
    }

    a {
      position: absolute;
      top: 12px;
      right: 12px;
      padding-right: 24px;

      i {
        position: absolute;
        top: -1px;
        right: 0;
        font-size: 18px;
      }
    }
  }

  &-weights {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;

    li {
      width: calc(50% - 6px);
      padding: 10px;
      border-radius: 6px;
      font-size: 15px;
      text-align: center;
      background: #e5e9eb;

      &:nth-child(2n+2) {
        margin-left: 12px;
      }

      &:nth-child(n+3) {
        margin-top: 12px;
      }

      &:nth-child(2) {
        background: #e6e6e6;
      }
    }

    span {
      display: block;
    }

    strong {
      display: block;
      font-size: 24px;
      line-height: 48px;
    }

    time {
      display: block;
      margin-top: 4px;
      font-size: 12px;
    }
  }

  &-button {
    position: relative;
    display: block;
    padding: 0 10px;
    margin-top: 12px;
    border-radius: 6px;
    font-size: 15px;
    line-height: 44px;
    background: #e5e9eb;
  }

  &-stats {
    margin-top: 12px;
    border-radius: 6px;
    background: #e5e9eb;

    h3 {
      position: relative;
      padding: 12px;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      font-size: 15px;
      line-height: 20px;
      background: #e6e6e6;

      span {
        position: absolute;
        right: 12px;
      }

      strong {
        font-weight: 600;
      }
    }

    dl {
      padding: 0 12px;
      display: flex;
      flex-wrap: wrap;
      font-size: 13px;

      &:nth-child(n+3) {
        border-top: 1px #d4d4d4 solid;
      }
    }

    dt {
      width: 80%;
    }

    dd {
      width: 20%;
      font-weight: 600;
      text-align: right;
    }

    dt,
    dd {
      padding: 14px 0;
    }
  }

  &-tr {
    display: flex;
    flex-wrap: wrap;
  }

  &-th,
  &-td {
    padding: 14px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:nth-child(1) {
      width: 12%;
    }

    &:nth-child(2) {
      width: 14%;
    }

    &:nth-child(3) {
      width: 14%;
    }

    &:nth-child(4) {
      width: 52%;
    }
    &:nth-child(5) {
      width: 8%;
      .icon-edit-1{
        font-size: 18px;
      }
    }
  }

  &-th {
    border-bottom: 1px #d4d4d4 solid;
    font-size: 14px;

    &[data-active="true"] {
      font-weight: 600;
    }

    &[data-sort] {
      position: relative;
      padding: 0 0 0 12px;

      button {
        position: relative;
        z-index: 1;
        display: inline;
        max-width: 100%;
        padding-left: 16px;
        line-height: 46px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 12px;
        display: block;
        width: 12px;
        height: 12px;
        margin-top: -6px;
        background: url('../SysIons/arrow-right.svg') no-repeat center center;
        background-size: contain;
        transform: rotate(90deg);
      }
    }

    &[data-sort="asc"] {
      &:after {
        transform: rotate(270deg);
      }
    }
  }

  &-td {
    font-size: 13px;
  }

  &-loadMore {
    width: 100%;
    border-top: 1px #d4d4d4 solid;
    font-size: 13px;
    line-height: 42px;
  }

  &-chart {
    height: 290px;
  }

  footer {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px #d4d4d4 solid;
    line-height: 42px;

    button {
      width: 25%;

      &[data-active="true"] {
        font-weight: 600;
      }
    }
  }
}
