@import './src/assets/styles/_colors.scss';

.loading_container {
  text-align: center;
  font-family: Nunito Sans,Helvetica,Arial,sans-serif;
  color: $color-kitchen-blue;
  font-size: 16px;

  .loading_wrapper {
    position: relative;
    display: inline-flex;

    .loading {
      position: relative;
    }

    .dot {
      position: absolute;
      border-top: 0;
      right: 0;
      width: 4px;
      height: 4px;
      background-color: rgba($color-kitchen-blue, 0.8);


      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      bottom: 22px;


      &.dot_one {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
        right: -8px;

      }

      &.dot_two {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
        right: -16px;
      }

      &.dot_three {
        right: -24px;
      }
    }
  }
}

.LoadWrapper {
  padding: 10px 0;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
