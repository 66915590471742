
.meetingBody {

  .title {
    width: 100%;
    font-size: 17px;
    font-weight: 550;
  }

  .meeting_actions {
    margin-top: 4vw;

    .buttonItem {
      margin-right: 5px;
    }
  }

  .instructorDataContainer {
    margin-top: 10px;
    width: 100%;
  }

  .attendanceList {
    width: 100%;
    margin-top: 4vw;

    .gridContainer {
      display: grid;
      grid-template-columns: 40px auto 150px 100px;

      @media (max-width: 480px) {
        // grid-template-columns: 40px auto 80px 80px;
        grid-template-columns: 40px repeat(2, 1fr);

        .box1 {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 3;
        }

        .box2 {
          grid-column-start: 2;
          grid-row-start: 1;
          grid-column-end: 4;
          grid-row-end: 2;
        }

      }


      &.header {
        border-top: 1px solid rgba(0,0,0,0.5);
        border-bottom: 1px solid rgba(0,0,0,0.5);
        font-weight: 600;
        background: rgba(1,135,102,0.1);

        .gridItem {
          font-size: 14px;
        }
      }

      &.body {
        border-bottom: 1px solid rgba(0,0,0,0.2);
      }

      .gridItem {
        padding: 6px 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .icon-check-circle {
          color: green;
        }

        .icon-cancel-circle {
          color: red;
        }

        @media (max-width: 480px) {
          font-size: 12px;
          align-self: center;
        }
      }
    }
  }
}

.ModalChangeMeetingDate {
  padding: 20px;

  &.ModalBody {
    margin-top: 10px;
    overflow: auto;
  }

  .course-list-container {
    height: 42vh;
    overflow: auto;
    margin-bottom: 15px;
  }

  .NotificationWrapper {
    padding: 30px;
    margin-bottom: 20px;

    .skip_allowed_warning {
      color: red;
    }

    .textWrapper {
      font-size: 1.5em;
      font-weight: normal;
      margin-bottom: 15px;
    }

    .ListContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      .ListItem {
        padding: 4px 0px;
        font-size: 1.2em;
      }
    }
  }

  .buttonWrapper {
    font-size: 16px;
  }

  .wbf-field-item {
    margin-bottom: 20px;

    label {
      display: inline-block;
      margin-bottom: 8px;
    }



    input[type="text"], input[type="number"] {
      height: 40px;
      text-align: left;
      width: 100%;
      padding: 10px;
      border: 1px #9d9d9d solid;
      border-radius: 6px;
      font: inherit;
      color: black;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    }

    .instructorAutocompleteList {
      position: relative;
      width: 100%;
      height: 1px;

      .instructorAutocompleteContainer {
        position: absolute;
        max-height: 200px;
        overflow-y: auto;
        top: 8px;
        left: 0px;
        width: 100%;
        background: #fff;

        .autocompleteItems {
          width: 100%;
          border-bottom: 1px solid rgba(0,0,0,0.2);
          padding: 8px 10px;
          font-size: 1em;
          cursor: pointer;
          transition: background 0.3s ease;

          &:hover {
            background: rgba(221,221,221,0.5);
          }

        }
      }
    }
  }

  footer {
    img {
      height: 10px;
    }
  }
}
