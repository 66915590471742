@import "../_colors.scss";

.emailPopup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;

  .emailPopupContainer {
    position: relative;
    width: 100%;
    height: 100%;

    .emailPopupWrapper {
      position: absolute;
      width: calc(100% - 32px);
      height: calc(100vh - 100px);
      max-width: 1174px;
      /*max-height: 440px;*/
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 101;
      background: $color-white;
      border-radius: 6px;
      padding: 16px 8px;
      overflow-x: hidden;
      overflow-y: auto;

      @media screen and (min-width: 768px) {
        width: calc(100% - 48px);
        padding: 20px 16px;
      }

      &::-webkit-scrollbar {
      	width: 0px;
      	background: transparent;
      }
      &::-webkit-scrollbar-thumb {
      	background: #FF0000;
      }

      .Medium-placeholder {
        height: calc(100vh - 338px);
        margin-bottom: 12px !important;

        @media screen and (min-width: 768px) {
          width: calc(100% - 32px);
          height: calc(100vh - 348px);
        }
      }

      .emailHeader {
        display: grid;
        grid-template-columns: 75px auto 75px;
        grid-template-rows: 30px;

        .closeWrapper {
          text-align: left;
          padding: 5px 10px 5px 0;

          .textWrapper {
            margin-left: 4px;
          }
        }

        .headWrapper {
          text-align: center;
          font-size: 16px;
          line-height: 30px;
        }

        .sendWrapper {
          text-align: center;
          padding: 5px 0 5px 10px;

          .textWrapper {
            margin-right: 4px;
          }

          &[data-state="sending"] {
            pointer-events: none;

            .textWrapper, .action-icons {
              visibility: hidden;
            }

            img {
              display: block;
            }
          }
        }

        .buttonWrapper {
          .action-icons {
            font-size: 20px;
          }
          .textWrapper {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .sendButton {
          position: relative;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            margin: -4px 0 0 -15px;
            display: none;
          }
        }
      }

      .emailSendDetails {
        margin-top: 12px;

        .emailTags {
          position: relative;
          width: 100%;
          background: $gray-list-head;
          border-radius: 4px;
          padding: 0 6px;
          min-height: 40px;
          display: inline-flex;
          font-size: 14px;

          .toTextWrapper {
            box-sizing: border-box;
            margin: 6px 6px 0 0;
            padding: 6px 2px;
          }

          .tagContainer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 40px;
            padding: 0 25px 0 25px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .nameItem {
              line-height: 40px;
              font-size: 10px;
            }

          }

          .addUser {
            position: absolute;
            top: 50%;
            right: 0;
            font-size: 20px;
            transform: translateY(-50%);
            padding: 5px;
            height: 30px;
          }
        }

        .userNameContainer {
          .userNameWrapper {
            width: 100%;
            padding: 8px 0 9px 0;
            border-bottom: 1px solid $border-gray;

            .toTextWrapper {
              font-size: 10px;
              float: left;
              margin-right: 3px;
            }

            .namesContainer div {
              font-size: 10px;
              float: left;
              margin-right: 3px;

              &::after {
                content: ",";
              }

              &:last-child{

                &::after {
                  content: "";
                }
              }
            }
          }
        }

        .attachmentContainer {
          .attachmentWrapper {
            width: 100%;
            padding: 8px 0 0 0;

            .countAttachments {
              font-size: 10px;
              display: inline-block;
              margin-right: 3px;
            }

            .attachmentsText {
              display: inline-block;

              div {
                font-size: 10px;
                display: inline-block;
                margin-right: 3px;
                white-space: nowrap;

                &::after {
                  content: ",";
                }

                &:last-child{

                  &::after {
                    content: "";
                  }
                }

                i {
                  position: relative;
                  top: 2px;
                  font-size: 13px;
                  cursor: pointer;
                }
              }
            }


          }
        }
      }

      .emailMessageBody {
        .actionButtons {
          position: relative;
          width: 100%;
          height: 30px;
          margin-top: 8px;
          margin-bottom: 8px;

          .emailContentAction {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.bold {
              left: 0;
              font-size: 14px!important;
              font-weight: 800;
              color: #000;
              padding: 6px;
              background: $gray-list-head;
              border-radius: 6px;
            }

            &.italic {
              left: 35px;
              font-size: 14px!important;
              font-weight: 800;
              color: #000;
              padding: 6px;
              background: $gray-list-head;
              border-radius: 6px;
            }

            &.attachment {
              right: 0;
              padding: 4px 2px;

              .action-icons {
                font-size: 20px;
              }

              .textWrapper {
                line-height: 20px;
                font-size: 14px;
                margin-left: 5px;
              }

              .uploadInpput {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
              }

            }
          }
        }

        .emailContent {
          width: 100%;
          height: 200px!important;
          background: $gray-list-head;
          border-radius: 6px;
          color: $color-black;
          font-size: 12px;
          padding: 12px 8px;
          outline: none;

          overflow-x: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
          	width: 0px;
          	background: transparent;
          }
          &::-webkit-scrollbar-thumb {
          	background: #FF0000;
          }
        }

      }

    }
  }
}

.Medium-placeholder {
  width: calc(100% - 16px);
  min-width: auto !important;
  height: 200px;
  background: $gray-list-head!important;
  border-radius: 6px!important;
  color: $color-black!important;
  font-size: 12px!important;
  padding: 12px 8px!important;
  border: none!important;

  &::focus {
    border:none!important;
  }
}

.Medium {
  height: 200px;
  width: 100%;
  background: $gray-list-head;
  border-radius: 6px;
  border: none!important;
  color: $color-black;
  font-size: 12px;
  padding: 12px 8px;

  &::focus {
    border:none!important;
    border-color: none!important;
  }
}

.emailMessageBody {
  margin-top: 12px;
}

.emailSubjectBody {
  margin-top: 12px;

  #subject {
    background: #e5e5e5 !important;
    border-radius: 6px !important;
    color: #000000 !important;
    font-size: 12px !important;
    padding: 4px 8px !important;
    border: none !important;
    width: 100%;
    height: 30px;
  }
}
