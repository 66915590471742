.asssitant-container {
  padding: 25px 12px 60px 12px;

  .assistant-header {
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: 0.2px;
    text-align: center;
    color: black;
  }

  .breadcrumbs {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: black;
    margin-top: 16px;
  }

  .input-container {
    margin-top: 20px;
    width: 100%;
    padding: 16px 0;

    .label-wrapper {
      margin-bottom: 8px;

      span {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .input-wrapper {
      textarea {
        resize: none;
        width: 100%;
        height: 200px;
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 16px;
        transition: all 0.3s ease;


        &:focus {
          outline: none;
          border: 1px solid rgba(0, 0, 0, 0.6);
        }
      }
    }

    .button-wrapper {
      &.right {
        text-align: right;
      }

      &.left {
        text-align: right;
      }

      &.center {
        text-align: right;
      }

      .loadingWrapper {
        display: inline-flex;
        height: 40px;
        position: inherit;
      }

      .button {
        display: inline-flex;
        height: 40px;
        padding: 10px 12px;
        margin-left: 12px;
        font-size: 16px;
        font-weight: 600;
        background: rgb(0, 37, 56);
        color: white;
        margin: 0 .25em .5em 0;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 6px;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          background: rgba(0, 37, 56, 0.8);
        }

      }
    }
  }
}
