@import "../_colors.scss";

.pageTitle {
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: 0.2px;
  text-align: center;
  color: black;
}

.breadcrumbs {
  position: relative;
  margin-top: 20px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: black;

  .prevAndNext {
    position: absolute;
    top: -5px;
    right: 0;
  }
  .prevAndNext a {
    margin-left: 8px;
  }
  .prevAndNext a[data-enabled="false"] {
    opacity: 0.3;
    pointer-events: none;
  }

  ul {
    overflow: hidden;
  }

  li {
    position: relative;
    float: left;

    &:last-child {
      font-weight: 600;
    }

    &:not(:last-child) {
      padding-right: 16px;
      margin-right: 4px;

      &:after {
        content: '\e805';
        position: absolute;
        right: 0;
        top: 5px;
        display: inline-block;
        transform: rotate(180deg);
        font-size: 10px;
        font-family: 'Simple-Line-Icons-Pro';
        font-weight: bold;
        color: #ccd3d7;
      }
    }
  }
}

.loadingData {
  height: 44px;
  margin-top: 20px;
  background: url('../SysIons/loading.svg') no-repeat center center;
  background-size: 100% 10px;
}

.emptyResultSet {
  // padding: 13px 10px;
  // margin-top: 20px;
  // border-radius: 6px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  // background: #d5d5d5;
}

.wb-dateNav {
  position: relative;
  margin: 20px -12px 0 -12px;
  border-top: 1px #e6e6e6 solid;
  border-bottom: 1px #e6e6e6 solid;
  font-size: 15px;
  line-height: 42px;
  text-align: center;

  @media screen and (min-width: 600px) {
    margin-left: 0;
    margin-right: 0;
  }

  a {
    position: absolute;
    font-size: 20px;

    &[data-disabled="true"] {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  i {
    line-height: inherit;
  }

  .prev {
    left: 12px;

    @media screen and (min-width: 600px) {
      left: 0;
    }
  }

  .next {
    right: 12px;

    @media screen and (min-width: 600px) {
      right: 0;
    }
  }

  span {
    padding-left: 12px;
    color: #667c88;
  }
}

.wbp {
  margin-top: 20px;
  border-radius: 6px;
  font-size: 13px;
  overflow: hidden;

  &-table {
    width: 100%;
  }

  &-tr {
    display: flex;

    &[data-disabled="true"] {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &-tr-link {
    cursor: pointer;

    &:hover {
      background: rgba(#d5d5d5, 0.65);
    }
  }

  &-tbiscrl {
    .wbp {
      &-tr {
        background: white !important;
        color: black !important;
        cursor: pointer;

        &:hover {
          background: rgba(229,233,235,.65)!important;
        }
      }
      &-tr.checked {
        color: #667c88 !important;
      }

      &-tr:nth-child(1) {
        font-size:inherit!important;

        & > div:first-child {
          font-weight: 400;
        }
      }

      &-tr:nth-child(2) {
        font-size:inherit!important;
      }
    }
  }

  &-tr:nth-child(1) {
    background: #002538;
    font-size: 15px;
    color: white;

    & > div:first-child {
      font-weight: 600;
    }
  }

  &-tr:nth-child(n+3) {
    background: #e5e9eb;
    cursor: pointer;

    &:hover {
      background: rgba(#e5e9eb, 0.65);
    }
  }

  &-tr:nth-child(2) {
    background: #ccd3d7;
    font-size: 14px;
    color: black;
  }

  &-th:nth-child(3),
  &-td:nth-child(3) {
    width: 142px;
    text-align: right;
  }

  &-td:not(:last-child) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-th,
  &-td {
    width: 50%;
    padding: 14px 10px;
    text-align: left;
  }

  &-td {
    &.removePadding {
        padding: 0!important
    }

    .wrapper {
      position: relative;
      padding: 14px 10px 14px 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.hasMore {
        padding-right: 20px!important;

        &.closed {
          &::after {
            @include menu-arrow();
            transform: rotate(270deg);
          }
        }

        &.open {
          &::after {
            @include menu-arrow();
            transform: rotate(90deg);
            top: 20px!important;
          }

          .courseName {
            margin-bottom: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:last-child {
              margin-bottom:0px;
            }
          }
        }
      }
    }
  }

  &-tr:nth-child(1) th {
    padding: 13px 10px;
  }

  &-td:last-child {
    text-align: center;
    font-size: 12px;

    @media screen and (min-width: 640px) {
      i {
        margin-left: 10px;
      }
    }
  }

  &-sort {
    position: relative;
    padding-left: 18px;
    cursor: pointer;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 76px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 12px;
      height: 12px;
      margin-top: -6px;
      background: url('../SysIons/arrow-right.svg') no-repeat center center;
      background-size: contain;
      transform: rotate(90deg);
    }

    &[data-sort="asc"]:after {
      transform: rotate(270deg);
    }

    &[data-active="true"] {
      font-weight: 600;
    }
  }

  &-showResults {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 51px;
    padding: 6px 12px;
    border-top: 1px #d5d5d5 solid;

    button {
      width: 100%;
      border-radius: 6px;
      line-height: 38px;
      background: #e5e9eb;
    }
  }
}

.arrow {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    display: block;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    background: url('../SysIons/arrow-right.svg') no-repeat center center;
    background-size: contain;
  }
}
