@import './src/assets/styles/_colors.scss';

.commentTextEditorContainer {
  margin-top: 10px;

  .commentTextEditorActionContainer {
    margin-top: 10px;
    text-align: right;

    .commentTextEditorActionWrapper {
      display: inline-flex;

      .actionItem {
        padding: 5px 10px;
        color: $color-black;
        cursor: pointer;
        text-decoration: none;
        margin-left: 5px;
      }
    }
  }
}
