.wbfm {
  padding: 12px;
  font-size: 18px;
}

.wbf {
  padding: 12px;
  margin-top: 20px;
  border-radius: 6px;
  font-size: 18px;
  border: 1px solid #e5e9eb;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, .3);

  &.form_set {
    margin-top: 0;
    border-radius: inherit;
    border: inherit;
    box-shadow: inherit;
  }


  &[data-processing="true"] {
    pointer-events: none;

    footer {
      button {
        visibility: hidden;
      }

      img {
        visibility: visible;
      }
    }
  }

  strong {
    font-weight: 600;
  }

  header {
    overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 2px;

    h2 {
      font-size: 16px;
      font-weight: 600;
      clear: both;
    }

    p {
      float: left;
      margin-top: 18px;
    }

    time {
      float: right;
      margin-top: 18px;
      font-size: 13px;
    }
  }



  footer {
    position: relative;
    height: 40px;
    margin: -2px 0;

    label {
      display: block;
      float: left;
      font-size: 12px;
      line-height: 30px;
    }

    input {
      position: relative;
      top: 4px;
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }

    button {
      display: block;
      float: right;
      height: 40px;
      padding: 10px 12px;
      margin-right: -12px;
      font-size: 16px;
      font-weight: 600;
      background: #002538;
      color: white;
      margin: 0 .25em .5em 0;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, .3);
      border-radius: 6px;
    }

    img {
      position: absolute;
      top: 11px;
      right: 0;
      height: 10px;
      visibility: hidden;
    }
  }

  &-field {
    position: relative;
    margin-bottom: 20px;

    label {
      display: inline-block;
      margin-bottom: 8px;
    }

    .registrationDateWrapper {
      font-size: 13px;
      margin: 8px 3px;
    }

    input[type="number"],
    input[type="text"],
    textarea {
      width: 100%;
      padding: 10px;
      border: 1px #9d9d9d solid;
      border-radius: 6px;
      font: inherit;
      color: black;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, .3);

      &:focus {
        box-shadow: 0 0 0 3px rgba(#9d9d9d, 0.3);
        background: #fff;
        outline: none;
      }
    }

    input[type="text"],
    input[type="number"] {
      height: 40px;
      text-align: right;
    }

    textarea {
      height: 80px;
    }

    .rc-slider {
      margin: 4px 0 26px;
    }
  }

  &-slider-val {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-overlay {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0.65);
  }

  &-modal {
    position: absolute;
    z-index: 101;
    left: 50%;
    top: 50%;
    width: 240px;
    margin: -10px 0 0 -120px;
    border-radius: 6px;
    font-size: 15px;
    background: white;

    header {
      position: relative;
      min-height: 42px;
      border-bottom: 1px #d8d8d8 solid;

      i {
        position: absolute;
        top: 12px;
        right: 16px;
        line-height: 18px;
        cursor: pointer;
      }
    }

    p {
      padding: 16px 16px;
      text-align: center;
    }
  }
}

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
      touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  & * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  &-rail {
    position: absolute;
    width: 100%;
    background-color: #d8d8d8;
    height: 6px;
    border-radius: 6px;
  }
  &-track {
    position: absolute;
    left: 0;
    height: 6px;
    border-radius: 6px;
    background-color: #b5b5b5;
  }
  &-handle {
    position: absolute;
    margin-left: -12px;
    margin-top: -10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    border-radius: 50%;
    background-color: #979797;
    -ms-touch-action: pan-x;
        touch-action: pan-x;
  }
  &-handle:focus {
    box-shadow: 0 0 0 3px rgba(#979797, 0.3);
    outline: none;
  }
  &-handle-click-focused:focus {
    box-shadow: unset;
  }
  &-handle:active {
    box-shadow: 0 0 0 2px rgba(#979797, 0.3);
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}



.seeDevelopment {
  position: relative;
  display: block;
  padding: 0 10px;
  margin-top: 12px;
  border-radius: 6px;
  font-size: 18px;
  line-height: 55px;
  background: #a4f48e;
  width: 150px;
  color: black;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, .3);
  cursor: pointer;

  &:hover {
    background: rgba(#e5e9eb, 0.65);
  }
}
